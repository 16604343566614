import { classNames, ExpandIcon, S3ImageView, Tooltip } from '@life/components'
import { Book, RenderElementProps } from '@life/frontend-model'
import { Link } from 'react-router-dom'
import { ToolbarActionEvent } from './Toolbar'

type Props = RenderElementProps & {
  book: Book
  selected: boolean
  handleAction?: ToolbarActionEvent
}

export function StoryImage(props: Props): JSX.Element {
  const { element, attributes, children, book, ...fxn } = props
  if (element.type !== 'image') throw new Error('Not an image')

  const img = book.findImage(element.id)
  if (!img)
    return (
      <div {...attributes} className="text-red-600">
        Missing image ${element.id} from book. You can remove this line.
      </div>
    )
  return (
    <div {...attributes}>
      <div
        contentEditable={false}
        className={classNames(
          'border-4',
          fxn.selected ? 'border-yellow-300' : 'border-transparent',
          element.size === 'small' ? 'float-left w-1/2 max-w-sm mr-3' : 'w-full max-w-prose clear-both'
        )}
      >
        <figure>
          <S3ImageView image={img} size="web" />
          <figcaption
            onClick={() => {
              const { handleAction } = props
              if (!handleAction) return
              handleAction({
                action: 'openImageDialog',
              })
            }}
            className="mt-2 mb-2 text-base text-gray-500 text-start cursor-pointer hover:bg-gray-100"
          >
            {element.caption ?? <span className="text-gray-400 italic">Click to add caption...</span>}
          </figcaption>
          <div className="flex justify-end">
            <Tooltip message="Tag people, add a date, or write notes">
              <Link to={img.link} className="float-right flex hover:text-cyan-500 text-cyan-600 cursor-pointer">
                <span className="hover:underline text-sm">Go to Photo</span>
                <ExpandIcon className="w-4" />
              </Link>
            </Tooltip>
          </div>
        </figure>
      </div>
      {children}
    </div>
  )
}
