import {
  ButtonSave,
  NavigationConfirmation,
  NotReady,
  PageHeading,
  PagePadding,
  useErrorNotification,
  useInterval,
  useRequiredParam,
} from '@life/components'
import { Story, useBook, useUpdateStory } from '@life/frontend-model'
import { StoryContent, StoryDate, StoryStatusType } from '@life/model'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { ParentStoryLink } from '../ParentStoryLink'
import { clearEditBackup, hasEditBackup, readEditBackup, storeEditBackup, RestorePrompt } from './AutoSave'
import { EditMenu } from './EditMenu'
import { Editor } from './Editor'
import { Occurred } from './Occurred'
import { Status } from './Status'
import { Title } from './Title'

export type StoryFormFields = { title: string; status: StoryStatusType; occurred: StoryDate; content: StoryContent[] }

export function EditStory(): JSX.Element {
  const [bookSlug, storyId] = useRequiredParam(['bookSlug', 'storyId'])
  const { book, isLoading, error } = useBook(bookSlug)
  const [canUseBackup, setCanUseBackup] = useState<boolean>()
  useEffect(() => {
    if (canUseBackup === undefined) {
      if (!hasEditBackup(storyId)) setCanUseBackup(false)
    }
  }, [canUseBackup, storyId])

  if (isLoading || error || !book || !bookSlug) {
    return <NotReady type="Book" id={bookSlug} isLoading={isLoading} error={error} />
  }
  const story = book.findStory(storyId)
  if (!story) return <NotReady type="Story" id={storyId} notFound />

  function handleRestorePrompt(shouldRestore: boolean): void {
    setCanUseBackup(shouldRestore)
    if (shouldRestore) {
      const content = readEditBackup(storyId)
      if (content && story) story.content = content
    } else {
      clearEditBackup(storyId)
    }
  }
  return (
    <>
      {canUseBackup !== undefined && <EditStoryWithBackup story={story} />}
      <RestorePrompt isOpen={canUseBackup === undefined} onClose={handleRestorePrompt} />
    </>
  )
}

type Props = {
  story: Story
}
function EditStoryWithBackup({ story }: Props): JSX.Element {
  const updater = useUpdateStory()
  const { showError } = useErrorNotification()
  useInterval(60, backup)
  const form = useForm<StoryFormFields>({ defaultValues: {} })

  const { handleSubmit, reset, control } = form
  useEffect(() => {
    reset({
      title: story?.title,
      status: story?.status,
      occurred: story?.occurred,
      content: story?.content,
    })
  }, [story, reset])

  async function update(fields: StoryFormFields): Promise<void> {
    try {
      story.title = fields.title
      story.status = fields.status
      story.occurred = fields.occurred
      story.content = fields.content
      const saved = await updater.update(story)
      reset({
        title: saved.title,
        status: saved.status,
        occurred: saved.occurred,
        content: saved.content,
      })
      clearEditBackup(story.storyId)
    } catch (error) {
      showError('Error Updating Story', error)
    }
  }
  function backup(): void {
    if (!form.formState.isDirty) return
    const content = form.getValues('content')
    storeEditBackup(story.storyId, content)
  }

  const { occurred, status } = form.watch()
  const { title } = form.watch()
  const { isDirty } = form.formState

  return (
    <PagePadding noPadding className="">
      <form onSubmit={handleSubmit(update)} className="w-full h-full flex flex-col">
        <ParentStoryLink parent={story.parent} edit={true} />
        <PageHeading
          title={['Story', story.title, 'Edit']}
          className="px-2 mt-1"
          right={
            <>
              <div className="flex justify-between items-center mb-2">
                <div className="flex gap-2 items-center">
                  <ButtonSave disabled={!isDirty} clicked={updater.isLoading} />
                  <EditMenu story={story} isDirty={isDirty} />
                </div>
              </div>
            </>
          }
        >
          <Title form={form} title={title} />
        </PageHeading>
        <div className="flex p-1 space-x-5 text-sm font-normal">
          <Occurred form={form} occurred={occurred} />
          <Status form={form} status={status} />
        </div>
        <Controller
          render={({ field }) => (
            <Editor initial={field.value} story={story} onChange={field.onChange} onBlur={field.onBlur} />
          )}
          control={control}
          name="content"
          defaultValue={story.content}
        />
        <NavigationConfirmation unsavedChanges={form.formState.isDirty} />
      </form>
    </PagePadding>
  )
}
