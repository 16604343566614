import { classNames, DocumentIcon, ExpandIcon } from '@life/components'
import { Book, Story } from '@life/frontend-model'
import { isDefined, isEmpty, StoryId } from '@life/model'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

type OutlineProps = {
  currentStory: Story
}
export function Outline({ currentStory }: OutlineProps): JSX.Element {
  const { book } = currentStory
  const { toc } = book

  function mapTocToStories(storyIds: StoryId[] | undefined): Story[] {
    if (isEmpty(storyIds)) return []
    return storyIds.map((s) => book.findStory(s)).filter(isDefined)
  }

  const intro = [...mapTocToStories(toc.beforeToc), ...mapTocToStories(toc.afterToc)]
  const chapters = mapTocToStories(toc.chapters2)
  const appendix = mapTocToStories(toc.appendix2)
  const notInBook = findChaptersNotInBook(book)

  return (
    <div className="h-full overflow-y-auto">
      <ChapterGroup title="Front Matter" book={book} chapters={intro} currentStory={currentStory} />
      <ChapterGroup title="Body" book={book} chapters={chapters} currentStory={currentStory} />
      <ChapterGroup title="Back Matter" book={book} chapters={appendix} currentStory={currentStory} />
      <ChapterGroup title="Not in Book" book={book} chapters={notInBook} currentStory={currentStory} />
    </div>
  )
}

function findChaptersNotInBook(book: Book): Story[] {
  return book.stories.filter((story) => {
    const top = story.getTopStory()
    return top === story && !top.isInToc()
  })
}

type LinkProps = {
  story: Story
  selected: boolean
}
function StoryLink({ story, selected }: LinkProps): JSX.Element {
  if (selected) {
    return <span className="flex-1 line-clamp-1 font-bold bg-gray-300 rounded-sm px-1">{story.title}</span>
  }
  return (
    <Link to={story.link} className={'flex-1 line-clamp-1 hover:underline'}>
      {story.title}
    </Link>
  )
}

type ChapterGroupProps = {
  title: string
  book: Book
  chapters: Story[]
  currentStory: Story
}
function ChapterGroup({ title, book, chapters, currentStory }: ChapterGroupProps): JSX.Element {
  if (!chapters || chapters.length === 0) return <></>
  return (
    <div className="pb-1">
      <h2 className="font-bold">{title}</h2>
      <ChapterList book={book} chapters={chapters} currentStory={currentStory} />
    </div>
  )
}

type ChapterListProps = {
  book: Book
  chapters: Story[] | undefined
  currentStory: Story
  parentIndex?: string
}
function ChapterList({ book, chapters, currentStory, parentIndex }: ChapterListProps): JSX.Element {
  return (
    <div className="pl-3">
      {chapters &&
        chapters.map((chapter, chapterIndex) => (
          <ChapterSingle
            key={chapter.storyId}
            book={book}
            chapter={chapter}
            index={chapterIndex}
            currentStory={currentStory}
            parentIndex={parentIndex}
          />
        ))}
    </div>
  )
}

type ChapterSingleProps = {
  book: Book
  chapter: Story
  index: number
  parentIndex: string | undefined
  currentStory: Story
}
function ChapterSingle({ book, chapter, index, parentIndex, currentStory }: ChapterSingleProps): JSX.Element {
  const [open, setOpen] = useState(false)
  const chapterIndex = index + 1
  const chapterNum = parentIndex ? parentIndex + '.' + chapterIndex : chapterIndex + ''
  const hasChildren = false
  useEffect(() => {
    if (chapter.getTopStory() === currentStory.getTopStory()) setOpen(true)
  }, [currentStory, chapter])

  return (
    <div key={chapter.key} className="">
      <div className="flex items-center space-x-2">
        <div className="flex items-center flex-1 space-x-2">
          {hasChildren ? (
            <ExpandIcon
              className={classNames(
                'h-4 w-4 cursor-pointer',
                open ? 'transition-transform rotate-90' : 'transition-transform rotate-0'
              )}
              onClick={() => setOpen((previous) => !previous)}
            />
          ) : (
            <DocumentIcon className={classNames('h-4 w-4 text-gray-400')} />
          )}
          <span>{chapterNum}</span>
          <StoryLink story={chapter} selected={currentStory.storyId === chapter.storyId} />
        </div>
      </div>
      {open && (
        <ChapterList book={book} chapters={chapter.substories} currentStory={currentStory} parentIndex={chapterNum} />
      )}
    </div>
  )
}
