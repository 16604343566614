import { API, BookId, InviteId, Logger } from '@life/model'
import { useMemo } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { serverRequest } from './api'
import { Book } from './book'
import { booksKey } from './book-api'
import { Invite, UnsavedInvite } from './invite'
import { ONE_MINUTE } from './time'

const logger = new Logger('api-invite')

const invitesKey = (bookId: BookId) => `INVITES:${bookId}`

export type InviteState = {
  isLoading: boolean
  error?: API.GetErrors
  invites?: Invite[]
}
export function useInvites(book: Book): InviteState {
  const query = useQuery<API.InviteGetActiveSuccess, API.GetErrors>(
    invitesKey(book.bookId),
    () => getActiveInvites({ bookId: book.bookId }),
    {
      retry: 1,
      staleTime: 15 * ONE_MINUTE,
    }
  )

  // Make sure hook result is stable
  const invites = useMemo(() => {
    logger.verbose('useInvites -> useMemo(invites)')
    // See story-api for explanation of this next line.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- use 'any' so we can delete the nonsense field
    delete (query.data as any)?.updatedAt
    return query.data?.invites ? query.data?.invites.map((inv) => new Invite(book, inv.invite, inv.url)) : undefined
  }, [query.data, book])
  const error = useMemo(() => query.error ?? undefined, [query.error])

  return {
    ...query,
    error,
    invites,
  }
}

export type CreateInviteState = {
  isCreating: boolean
  create: (invite: UnsavedInvite) => Promise<void>
}
export function useCreateInvite(): CreateInviteState {
  const queryClient = useQueryClient()
  const mutation = useMutation((input: API.InviteCreateInput) => createInvite(input))
  async function create(invite: UnsavedInvite): Promise<void> {
    try {
      const output = await mutation.mutateAsync({ invite: invite.toUnsavedModel() })
      queryClient.setQueryData<API.InviteGetActiveSuccess | undefined>(invitesKey(invite.bookId), (previous) => {
        if (!previous?.invites) return previous
        previous.invites.push({ invite: output.invite, url: output.url })
        // Use the "updatedAt" hack described in story-api to force Invites to reload.
        return { ...previous, updatedAt: Date.now() }
      })
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, isCreating: mutation.isLoading, create }
}

export type RemoveInviteState = {
  isRemoving: boolean
  remove: (invite: Invite) => Promise<void>
}
export function useRemoveInvite(): RemoveInviteState {
  const queryClient = useQueryClient()
  const mutation = useMutation((input: API.InviteRemoveInput) => removeInvite(input))
  async function remove(invite: Invite): Promise<void> {
    try {
      await mutation.mutateAsync({ bookId: invite.bookId, inviteId: invite.inviteId })
      queryClient.setQueryData<API.InviteGetActiveSuccess | undefined>(invitesKey(invite.bookId), (previous) => {
        if (!previous) return previous
        previous.invites = previous.invites.filter((inv) => inv.invite.inviteId !== invite.inviteId)
        // Use the "updatedAt" hack described in story-api to force Invites to reload.
        return { ...previous, updatedAt: Date.now() }
      })
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, isRemoving: mutation.isLoading, remove }
}

export type AcceptInviteState = {
  isAccepting: boolean
  accept: (bookId: BookId, inviteId: InviteId) => Promise<void>
}
export function useAcceptInvite(): AcceptInviteState {
  const queryClient = useQueryClient()
  const mutation = useMutation((input: API.InviteAcceptInput) => acceptInvite(input))
  async function accept(bookId: BookId, inviteId: InviteId): Promise<void> {
    try {
      await mutation.mutateAsync({ id: bookId, inviteId })
      queryClient.invalidateQueries(booksKey)
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, isAccepting: mutation.isLoading, accept }
}

function getActiveInvites(input: API.InviteGetActiveInput): Promise<API.InviteGetActiveSuccess> {
  return serverRequest<API.InviteGetActiveInput, API.InviteGetActiveSuccess>('/invite/get', input)
}

function createInvite(input: API.InviteCreateInput): Promise<API.InviteCreateSuccess> {
  return serverRequest<API.InviteCreateInput, API.InviteCreateSuccess>('/invite/create', input)
}

function removeInvite(input: API.InviteRemoveInput): Promise<API.ResponseSuccess> {
  return serverRequest<API.InviteRemoveInput, API.InviteRemoveSuccess>('/invite/remove', input)
}

function acceptInvite(input: API.InviteAcceptInput): Promise<API.ResponseSuccess> {
  return serverRequest<API.InviteAcceptInput, API.InviteAcceptSuccess>('/invite/accept', input)
}
