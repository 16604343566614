import {
  ActionMenuIcon,
  ButtonCancel,
  ButtonRemove,
  classNames,
  DeleteIcon,
  LifeDialog,
  LifeMenu,
  useErrorNotification,
} from '@life/components'
import { Book, Story, useRemoveStory } from '@life/frontend-model'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PdfPreviewLink } from '../PdfPreviewLink'

type PreviewProps = {
  book: Book
  story: Story
}
export function ReaderMenu({ book, story }: PreviewProps): JSX.Element {
  const [confirmDelete, setConfirmDelete] = useState(false)

  const disabled = !story.isInToc()
  return (
    <>
      <LifeMenu>
        <LifeMenu.Button className="p-1 rounded-full text-white">
          <ActionMenuIcon className="h-5 w-5 text-black hover:text-gray-400" aria-hidden="true" />
        </LifeMenu.Button>
        <LifeMenu.Items>
          <LifeMenu.Item>
            {({ active }) => (
              <div
                className={classNames(
                  active && !disabled ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block w-full text-left px-4 py-2 text-sm'
                )}
              >
                <PdfPreviewLink book={book} storyId={story.storyId} disabled={disabled} reason="Story is not in Book" />
              </div>
            )}
          </LifeMenu.Item>
          <LifeMenu.Item>
            {({ active }) => (
              <button
                type="button"
                className={classNames(
                  'flex w-full items-center text-left px-4 py-2 text-sm rounded-md',
                  active ? 'bg-red-500 text-white' : 'text-gray-700'
                )}
                onClick={() => setConfirmDelete(true)}
              >
                <DeleteIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                Delete
              </button>
            )}
          </LifeMenu.Item>
        </LifeMenu.Items>
      </LifeMenu>
      <ConfirmDialog
        key={String(confirmDelete)}
        story={story}
        isOpen={confirmDelete}
        onClose={() => setConfirmDelete(false)}
      />
    </>
  )
}

type ConfirmProps = {
  key: string // Trick to force dialog to reset state when it's opened. Should be set to String(isOpen)
  story: Story
  isOpen: boolean
  onClose: VoidFunction
}
function ConfirmDialog({ isOpen, onClose, story }: ConfirmProps): JSX.Element {
  const navigate = useNavigate()
  const remover = useRemoveStory()
  const { showError } = useErrorNotification()

  const shortcuts = { Escape: onClose }
  return (
    <LifeDialog modal title="Confirmation" isOpen={isOpen} shortcuts={shortcuts} onClose={onClose}>
      <LifeDialog.Content>Are you sure you want to remove this story from your book?</LifeDialog.Content>
      <LifeDialog.Actions>
        <ButtonCancel onClick={onClose} />
        <ButtonRemove
          confirm={false}
          clicked={remover.isRemoving}
          onClick={async () => {
            try {
              await remover.remove(story)
              navigate('../stories')
            } catch (error) {
              showError('Error Removing Story', error)
            }
          }}
        >
          Delete From Book
        </ButtonRemove>
      </LifeDialog.Actions>
    </LifeDialog>
  )
}
