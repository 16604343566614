import {
  ButtonBack,
  ButtonSave,
  classNames,
  HelpText,
  PageHeading,
  PagePadding,
  useErrorNotification,
} from '@life/components'
import { UnsavedBook, useAddBook, useUser } from '@life/frontend-model'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

export function AddBook() {
  type FormFields = {
    title: string
    author: string
  }
  const navigate = useNavigate()
  const { user } = useUser()
  const adder = useAddBook()
  const { showError } = useErrorNotification()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFields>({
    defaultValues: {
      title: '',
      author: user?.formalNameLastFirst ?? '',
    },
  })

  async function add(fields: FormFields): Promise<void> {
    try {
      const book = new UnsavedBook({
        slug: '',
        ...fields,
      })
      const saved = await adder.add(book)
      navigate('/book/' + saved.bookId)
    } catch (error) {
      showError('Error Adding Book', error)
    }
  }

  return (
    <PagePadding narrow>
      <PageHeading title="Add Book">Add Book</PageHeading>

      <form onSubmit={handleSubmit(add)} className="py-8 space-y-6 sm:space-y-5">
        <div>
          <input
            type="text"
            autoComplete="off"
            className={classNames(
              errors.title
                ? 'text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 ring-red-400 border-red-300'
                : 'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300',
              'w-full rounded-md'
            )}
            autoFocus
            placeholder="Book Title"
            {...register('title', {
              required: {
                value: true,
                message: 'Book Title is required',
              },
              minLength: {
                value: 5,
                message: 'Book Titles should be at least 5 characters long',
              },
              maxLength: {
                value: 50,
                message:
                  'Please keep book titles to less than 50 characters. You will have a chance to add a subtitle later if you need more space.',
              },
            })}
          />
          <HelpText error={errors.title}>
            The main title of your new book. If you don&apos;t have a title yet, then just enter your name.
          </HelpText>
        </div>
        <div>
          <input
            type="text"
            autoComplete="name"
            className={classNames(
              errors.author
                ? 'text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 ring-red-400 border-red-300'
                : 'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300',
              'w-full rounded-md'
            )}
            placeholder="Author"
            {...register('author')}
          />
          <HelpText error={errors.title}>The book author(s).</HelpText>
        </div>
        <div className="mt-8 text-center w-full flex flex-row justify-between">
          <ButtonBack />
          <ButtonSave clicked={adder.isLoading}>Add Book</ButtonSave>
        </div>
      </form>
    </PagePadding>
  )
}
