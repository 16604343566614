import { Book, Image, Story } from '@life/frontend-model'
import { isDefined, isEmpty, S3Image, StoryId, StoryImage } from '@life/model'

export function storyImageToImage(book: Book, row: StoryImage): Image {
  const img = book.findImage(row.id)
  return img ?? new Image(book, { original: '', web: '', thumb: '' } as S3Image)
}

export function tocStories(book: Book): { frontMatter: Story[]; body: Story[]; backMatter: Story[] } {
  function mapTocToStories(storyIds: StoryId[] | undefined): Story[] {
    if (isEmpty(storyIds)) return []
    return storyIds.map((s) => book.findStory(s)).filter(isDefined)
  }

  const { toc } = book
  const frontMatter = [...mapTocToStories(toc.beforeToc), ...mapTocToStories(toc.afterToc)]
  const body = mapTocToStories(toc.chapters2)
  const backMatter = mapTocToStories(toc.appendix2)
  return { frontMatter, body, backMatter }
}
