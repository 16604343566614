import { Toolbar, ToolbarButtons } from '@life/components'
import { Person, Story, Translate } from '@life/frontend-model'
import {
  createTask,
  isStoryImage,
  isStoryParagraph,
  isStorySubstory,
  MAX_TOC_LEVELS,
  StoryElement,
  StoryParagraphType,
} from '@life/model'
import { Dispatch, KeyboardEvent, SetStateAction, useEffect, useState } from 'react'
import { Editor, Transforms } from 'slate'
import { cacheCaption } from './caption-cache'
import { ImageDialog } from './ImageDialog'
import { PersonLinkDialog } from './PersonLinkDialog'
import { SubstoryDialog } from './SubstoryDialog'
import { Format, StoryEditor } from './types'
import {
  addImageAtCursor,
  addLinkAtSelection,
  addSubstoryAtSelection,
  EditorUtil,
  isFormatActive,
  isLinkAtSelection,
  PartialStoryImage,
  removeLinkAtSelection,
  toggleFormat,
  updateImageAtCursor,
} from './utils'

type ToolbarProps = {
  story: Story
  editor: StoryEditor
  setHandleKeyDown: Dispatch<SetStateAction<KeyDown | undefined>>
  setHandleAction: Dispatch<SetStateAction<ToolbarActionEvent | undefined>>
}

export type KeyDown = (event: KeyboardEvent) => void
export type ToolbarActionEvent = (action: ToolbarAction) => void
export type ToolbarAction = {
  action: 'openImageDialog'
}

export function ToolbarWithEditor({ story, editor, setHandleKeyDown, setHandleAction }: ToolbarProps): JSX.Element {
  const [personDialogIsOpen, setPersonDialogIsOpen] = useState(false)
  const [imageDialogIsOpen, setImageDialogIsOpen] = useState(false)
  const [substoryDialogIsOpen, setSubstoryDialogIsOpen] = useState(false)

  useEffect(() => {
    function handleKeyboard(event: KeyboardEvent): void {
      if (event.repeat || (!event.ctrlKey && !event.metaKey)) return
      const keys: Record<string, Format> = {
        b: 'bold',
        i: 'italic',
        u: 'underline',
      }
      const action = keys[event.key]
      if (action) {
        // TODO Consider how to incorporate all the values from toolbar
        toggleFormat(editor, action)
        event.preventDefault()
        return
      }
    }

    function handleAction(action: ToolbarAction): void {
      if (action.action === 'openImageDialog') {
        setImageDialogIsOpen(true)
        return
      }
    }

    setHandleKeyDown(() => handleKeyboard)
    setHandleAction(() => handleAction)
  }, [setHandleKeyDown, setHandleAction, editor])

  const util = new EditorUtil(editor)

  const element = util.getElementAtRow()
  const block = element && new Block(element)
  const isParagraph = isStoryParagraph(element)
  const isImage = isStoryImage(element)
  const isSection = isStorySubstory(element)
  const isLink = isLinkAtSelection(editor)

  const buttons: ToolbarButtons = {
    undo: { onClick: editor.undo, disabled: !editor.history.undos.length },
    redo: { onClick: editor.redo, disabled: !editor.history.redos.length },
    moveDown: { onClick: () => util.moveDown(), disabled: util.isLastRow(), hidden: !element },
    moveUp: { onClick: () => util.moveUp(), disabled: util.isFirstRow(), hidden: !element },
    erase: {
      onClick: () => util.erase(),
      hidden: !element,
      tooltip:
        block?.type === 'paragraph' || block?.type === 'task'
          ? `Delete ${block?.name}`
          : `Remove ${block?.name} from Story`,
    },
    block: block?.name,
    bold: {
      onClick: () => toggleFormat(editor, 'bold'),
      selected: isFormatActive(editor, 'bold'),
      hidden: !isParagraph,
    },
    italic: {
      onClick: () => toggleFormat(editor, 'italic'),
      selected: isFormatActive(editor, 'italic'),
      hidden: !isParagraph,
    },
    underline: {
      onClick: () => toggleFormat(editor, 'underline'),
      selected: isFormatActive(editor, 'underline'),
      hidden: !isParagraph,
    },
    indent: {
      onClick: () => {
        if (!isParagraph) return
        const padding = element.leftPadding === undefined ? 1 : undefined
        Transforms.setNodes(
          editor,
          { leftPadding: padding },
          {
            at: editor.selection?.focus,
          }
        )
      },
      tooltip: isParagraph && element.leftPadding ? 'Unindent' : 'Indent',
      selected: isParagraph && element.leftPadding != undefined,
      hidden: !isParagraph,
    },
    tag: {
      onClick: () => {
        if (isLink) removeLinkAtSelection(editor)
        else setPersonDialogIsOpen(true)
      },
      disabled: !isLink && !util.hasSelection(),
      hidden: !isParagraph,
      selected: isLink,
      tooltip: isLink ? 'Remove Tag' : 'Tag Person',
    },
    imageEdit: {
      onClick: () => {
        setImageDialogIsOpen(true)
      },
      hidden: !isImage,
      selected: true,
      tooltip: 'Edit Photo',
    },
    imageInsert: {
      onClick: () => {
        const row = util.getSelectedRow()
        if (row === false) return
        setImageDialogIsOpen(true)
      },
      disabled: !util.isFocused(),
      tooltip: 'Insert Photo',
    },
    sizeImage: {
      onClick: () => {
        if (!isImage) return
        const size = element.size === 'small' ? 'large' : 'small'
        Transforms.setNodes(
          editor,
          { size: size },
          {
            at: editor.selection?.focus,
          }
        )
      },
      hidden: !isImage,
      tooltip: 'Toggle Image Size',
    },
    comment: {
      onClick: clickMe,
      disabled: !util.hasSelection(),
      hidden: true, // TODO support comments
      tooltip: 'Insert Comment',
    },
    task: {
      onClick: () => {
        const row = util.getSelectedRow()
        if (row === false) return
        util.focus()
        Transforms.insertNodes(editor, createTask(), { at: [row] })
        Transforms.select(editor, {
          anchor: Editor.start(editor, []),
          focus: Editor.end(editor, []),
        })
      },
      disabled: !util.isFocused(),
      tooltip: 'Insert Task',
    },
    substory: {
      onClick: () => {
        const row = util.getSelectedRow()
        if (row === false) return
        setSubstoryDialogIsOpen(true)
      },
      tooltip: isSection ? 'Edit SubStory' : 'Insert Substory',
      hidden: !util.isFocused() || story.level >= MAX_TOC_LEVELS,
    },
  }

  return (
    <div>
      <Toolbar buttons={buttons} />
      <PersonLinkDialog
        key={`person-${personDialogIsOpen}`}
        isOpen={personDialogIsOpen}
        book={story.book}
        searchText={util.getSelectedText()}
        onClose={() => {
          setPersonDialogIsOpen(false)
          util.focus()
        }}
        addLink={(person: Person) => {
          addLinkAtSelection(editor, person.personId)
          setPersonDialogIsOpen(false)
        }}
      />
      <ImageDialog
        key={`image-${imageDialogIsOpen}`}
        isOpen={imageDialogIsOpen}
        book={story.book}
        image={isImage ? element : undefined}
        onClose={() => {
          setImageDialogIsOpen(false)
          util.focus()
        }}
        addImage={(image: PartialStoryImage) => {
          addImageAtCursor(editor, image)
          cacheCaption(image)
          setImageDialogIsOpen(false)
        }}
        saveImage={(image: PartialStoryImage) => {
          updateImageAtCursor(editor, image)
          cacheCaption(image)
          setImageDialogIsOpen(false)
        }}
      />
      <SubstoryDialog
        key={`substory-${substoryDialogIsOpen}`}
        isOpen={substoryDialogIsOpen}
        parentStory={story}
        content={[]}
        onChoose={function (story: Story): void {
          addSubstoryAtSelection(editor, story.storyId)
          setSubstoryDialogIsOpen(false)
          util.focus()
        }}
        onClose={() => {
          setSubstoryDialogIsOpen(false)
          util.focus()
        }}
      />
    </div>
  )
}

/** dummy stub method, delete once it's no longer used */
function clickMe() {
  console.info('Clicked')
}

class Block {
  constructor(readonly element: StoryElement) {}

  get type(): StoryParagraphType {
    const { element } = this
    switch (element.type) {
      case 'person':
      case 'location':
      case 'thing':
      case 'comment':
        return 'paragraph'
      default:
        return element.type
    }
  }
  get name(): string {
    return Translate.StoryParagraph(this.type)
  }
}
