import { API } from '@life/model'
import { useMutation, useQueryClient } from 'react-query'
import { serverRequest } from './api'
import { bookKey } from './book-api'
import { Person, UnsavedPerson } from './person'

export type AddPersonState = {
  isLoading: boolean
  add: (person: UnsavedPerson) => Promise<Person>
}
export function useAddPerson(): AddPersonState {
  const queryClient = useQueryClient()
  const mutation = useMutation((input: API.PersonAddInput) => addPerson(input))
  async function add(person: UnsavedPerson): Promise<Person> {
    try {
      const output = await mutation.mutateAsync({ person: person.toUnsavedModel() })
      queryClient.setQueryData<API.BookGetSuccess | undefined>(bookKey(person.book), (previous) => {
        if (!previous?.book) return previous
        previous.book.content.people.push(output.person)
        // Use the "updatedAt" hack described in story-api to force Book to reload.
        return { ...previous, updatedAt: Date.now() }
      })
      return new Person(person.book, output.person)
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, add }
}

export type UpdatePersonState = {
  isLoading: boolean
  update: (person: Person) => Promise<Person>
}
export function useUpdatePerson(): UpdatePersonState {
  const queryClient = useQueryClient()
  const mutation = useMutation((input: API.PersonUpdateInput) => updatePerson(input))
  async function update(person: Person): Promise<Person> {
    try {
      const output = await mutation.mutateAsync({ person: person.toModel() })
      queryClient.setQueryData<API.BookGetSuccess | undefined>(bookKey(person.book), (previous) => {
        if (!previous?.book) return undefined
        const { content } = previous.book
        content.people = content.people.map((person) =>
          person.personId === output.person.personId ? output.person : person
        )
        // Use the "updatedAt" hack described in story-api to force Book to reload.
        return { ...previous, updatedAt: Date.now() }
      })
      return new Person(person.book, output.person)
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, update }
}

export type RemovePersonState = {
  isRemoving: boolean
  remove: (person: Person) => Promise<void>
}
export function useRemovePerson(): RemovePersonState {
  const queryClient = useQueryClient()
  const mutation = useMutation((input: API.PersonRemoveInput) => removePerson(input))
  async function remove(person: Person): Promise<void> {
    try {
      const output = await mutation.mutateAsync({ bookId: person.book.bookId, personId: person.personId })
      queryClient.setQueryData<API.BookGetSuccess | undefined>(bookKey(person.book), (previous) => {
        if (!previous?.book) return undefined
        previous.book.content.people = previous.book.content.people.filter(
          (person) => person.personId !== output.personId
        )
        return previous
      })
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, remove, isRemoving: mutation.isLoading }
}

function addPerson(input: API.PersonAddInput): Promise<API.PersonAddSuccess> {
  return serverRequest<API.PersonAddInput, API.PersonAddSuccess>('/person/add', input)
}

function updatePerson(input: API.PersonUpdateInput): Promise<API.PersonUpdateSuccess> {
  return serverRequest<API.PersonUpdateInput, API.PersonUpdateSuccess>('/person/update', input)
}

function removePerson(input: API.PersonRemoveInput): Promise<API.PersonRemoveSuccess> {
  return serverRequest<API.PersonRemoveInput, API.PersonRemoveSuccess>('/person/remove', input)
}
