import { classNames, DocumentIcon, ExpandIcon } from '@life/components'
import { Book, Story } from '@life/frontend-model'
import { useEffect, useState } from 'react'
import { useScrollState } from './ScrollState'
import { tocStories } from './util'

type Props = {
  book: Book
}
export function Outline({ book }: Props): JSX.Element {
  const { frontMatter, body, backMatter } = tocStories(book)

  return (
    <div className="h-full overflow-y-auto">
      <ChapterGroup title="Front Matter" book={book} chapters={frontMatter} />
      <ChapterGroup title="Body" book={book} chapters={body} />
      <ChapterGroup title="Back Matter" book={book} chapters={backMatter} />
    </div>
  )
}

type LinkProps = {
  story: Story
}
function StoryLink({ story }: LinkProps): JSX.Element {
  const { scrollTo, currentStory } = useScrollState()
  if (story.storyId === currentStory) {
    return <span className="line-clamp-1 font-bold bg-gray-300 rounded-sm px-1">{story.title}</span>
  }
  return (
    <button onClick={() => scrollTo(story.storyId, story.storyId)}>
      <div className="text-left line-clamp-1 hover:underline">{story.title}</div>
    </button>
  )
}

type ChapterGroupProps = {
  title: string
  book: Book
  chapters: Story[]
}
function ChapterGroup({ title, book, chapters }: ChapterGroupProps): JSX.Element {
  if (!chapters || chapters.length === 0) return <></>
  return (
    <div className="pb-1">
      <h2 className="font-bold">{title}</h2>
      <ChapterList book={book} chapters={chapters} />
    </div>
  )
}

type ChapterListProps = {
  book: Book
  chapters: Story[] | undefined
  parentIndex?: string
}
function ChapterList({ book, chapters, parentIndex }: ChapterListProps): JSX.Element {
  return (
    <div className="pl-3">
      {chapters &&
        chapters.map((chapter, chapterIndex) => (
          <ChapterSingle
            key={chapter.storyId}
            book={book}
            chapter={chapter}
            index={chapterIndex}
            parentIndex={parentIndex}
          />
        ))}
    </div>
  )
}

type ChapterSingleProps = {
  book: Book
  chapter: Story
  index: number
  parentIndex: string | undefined
}
function ChapterSingle({ book, chapter, index, parentIndex }: ChapterSingleProps): JSX.Element {
  const [open, setOpen] = useState(false)
  const { currentStory } = useScrollState()
  const chapterIndex = index + 1
  const chapterNum = parentIndex ? parentIndex + '.' + chapterIndex : chapterIndex + ''
  const hasChildren = chapter.substories.length > 0
  useEffect(() => {
    const story = book.findStory(currentStory)
    setOpen(chapter.getTopStory() === story?.getTopStory())
  }, [book, currentStory, chapter])

  return (
    <div key={chapter.key} className="">
      <div className="flex items-center space-x-2">
        <div className="h-full">
          {hasChildren ? (
            <ExpandIcon
              className={classNames(
                'h-4 w-4 cursor-pointer',
                open ? 'transition-transform rotate-90' : 'transition-transform rotate-0'
              )}
              onClick={() => setOpen((previous) => !previous)}
            />
          ) : (
            <DocumentIcon className="h-4 w-4 text-gray-400" />
          )}
        </div>
        <div>{chapterNum}</div>
        <StoryLink story={chapter} />
      </div>
      {open && <ChapterList book={book} chapters={chapter.substories} parentIndex={chapterNum} />}
    </div>
  )
}
