import { Book } from '@life/frontend-model'
import { isStoryText, StoryContent } from '@life/model'
import { ViewImage } from './ViewImage'
import { ViewLink } from './ViewLink'
import { ViewParagraph } from './ViewParagraph'
import { ViewSection as ViewSubstory } from './ViewSubstory'
import { ViewTask } from './ViewTask'
import { ViewText } from './ViewText'

type Props = {
  book: Book
  content: StoryContent[]
}

export function ViewContent({ book, content }: Props): JSX.Element {
  let alignImageLeft = false

  return (
    <>
      {content &&
        content.map((row, index) => {
          if (isStoryText(row)) {
            return <ViewText key={index} text={row} />
          }
          switch (row.type) {
            case 'paragraph':
              return <ViewParagraph key={index} book={book} paragraph={row} />
            case 'task':
              return <ViewTask key={index} book={book} task={row} />
            case 'substory':
              alignImageLeft = false
              return <ViewSubstory key={row.id} book={book} section={row} />
            case 'image':
              if (row.size === 'small') {
                alignImageLeft = !alignImageLeft
              } else {
                alignImageLeft = false
              }
              return <ViewImage key={index} book={book} image={row} alignImageLeft={alignImageLeft} />
            case 'person':
            case 'location':
            case 'thing':
              return <ViewLink key={index} book={book} noun={row} />
            default:
              return <ViewContent key={index} book={book} content={row.children} />
          }
        })}
    </>
  )
}
