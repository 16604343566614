import { ButtonAdd, ButtonCancel, LifeDialog } from '@life/components'
import { Story } from '@life/frontend-model'
import { MAX_TOC_LEVELS, StoryContent } from '@life/model'
import { useState } from 'react'
import { AddStoryDialog } from '../AddStoryDialog'
import { StoryList } from '../list'

type Props = {
  key: string // Trick to force dialog to reset state when it's opened. Should be set to String(isOpen)
  parentStory: Story
  content?: StoryContent[]
  onChoose: (story: Story) => void
  isOpen: boolean
  onClose: () => void
}
export function SubstoryDialog({ isOpen, onClose, parentStory, content, onChoose }: Props): JSX.Element {
  const [addIsOpen, setAddIsOpen] = useState(false)
  const { book } = parentStory

  // Only show stories that don't contain this one (avoid cycles) and that won't increase story
  // levels beyond the max.
  const selectableStories = book.stories.filter(
    (story) => !story.containsStory(parentStory.storyId) && story.levelCount < MAX_TOC_LEVELS
  )

  const shortcuts = { Escape: onClose }
  return (
    <>
      <LifeDialog modal isOpen={isOpen} title="Select Substory" onClose={onClose} shortcuts={shortcuts}>
        <LifeDialog.Content className="px-1 max-w-3xl">
          <StoryList mode="choose" book={book} stories={selectableStories} onChoose={onChoose} />
        </LifeDialog.Content>
        <LifeDialog.Actions>
          <ButtonCancel onClick={onClose} />
          <ButtonAdd onClick={() => setAddIsOpen(true)}>Add New Story</ButtonAdd>
        </LifeDialog.Actions>
      </LifeDialog>
      <AddStoryDialog
        key={String(addIsOpen)}
        isOpen={addIsOpen}
        book={book}
        content={content}
        onSave={(story: Story) => {
          onChoose(story)
          setAddIsOpen(false)
        }}
        onClose={() => setAddIsOpen(false)}
      />
    </>
  )
}
