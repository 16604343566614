import { API, ImageId, Logger, S3Image } from '@life/model'
import { useCallback, useRef, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { serverRequest } from './api'
import { Book } from './book'
import { bookKey } from './book-api'
import { Image } from './image'
import { UploadState, useUploadFile } from './upload'

const logger = new Logger('api-image')

type AddImageState = UploadState & {
  imageId?: ImageId
}
export function useAddImage(book: Book): AddImageState {
  const [isAdding, setAdding] = useState(false)
  const [imageId, setImageId] = useState<ImageId>()
  const [error, setError] = useState<string>()
  const queryClient = useQueryClient()
  const { upload, abort: abortUpload, status, progress } = useUploadFile()
  const imageRef = useRef<S3Image>()

  const add = useCallback(
    async (file: File): Promise<void> => {
      if (!book) {
        setError('book is missing')
        return
      }

      // Insert image to DB
      let result: API.ImageAddSuccess
      try {
        setAdding(true)
        result = await addImage({
          image: {
            bookId: book.bookId,
            name: file.name,
            extension: stripImageExtension(file.type),
            size: file.size,
            // TODO consider how to get the height, width, and photo date from image metadata
          },
        })
      } catch (error) {
        setError((error as API.AddErrors).error)
        return
      } finally {
        setAdding(false)
      }

      // Update local copy and the book
      const image = result.image
      imageRef.current = image
      setImageId(image.imageId)
      queryClient.setQueryData<API.BookGetSuccess | undefined>(bookKey(book), (previous) => {
        if (!previous?.book) return previous
        previous.book.content.images.push(image)
        return previous
      })

      const uploadUrl = result.image.upload
      if (!uploadUrl) {
        setError('Failed to get upload url from server')
        return
      }
      upload(uploadUrl, addImageExtension(image.extension), file)
    },
    [book, queryClient, upload]
  )
  async function handleAbortClick(): Promise<void> {
    abortUpload()
    if (imageRef.current) {
      await removeImage(imageRef.current)
    }
  }
  return {
    upload: add,
    status: isAdding ? 'adding' : error ? 'error' : status,
    progress,
    abort: handleAbortClick,
    imageId,
    error,
  }
}

const prefix = 'image/'
function stripImageExtension(fileType: string) {
  if (fileType.startsWith(prefix)) return fileType.substring(prefix.length)
  throw new Error('Image types should be of type image/*')
}

function addImageExtension(extension: string) {
  if (extension.startsWith(prefix)) return extension
  return prefix + extension
}

export type RemoveImageState = {
  isRemoving: boolean
  remove: (image: Image) => Promise<void>
}
export function useRemoveImage(): RemoveImageState {
  const queryClient = useQueryClient()
  const mutation = useMutation((input: API.ImageRemoveInput) => removeImage(input))
  async function remove(image: Image): Promise<void> {
    try {
      const output = await mutation.mutateAsync({ bookId: image.bookId, imageId: image.imageId })
      queryClient.setQueryData<API.BookGetSuccess | undefined>(bookKey(image.book), (previous) => {
        if (!previous?.book) return previous
        previous.book.content.images = previous.book.content.images.filter((image) => image.imageId !== output.imageId)
        return previous
      })
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { isRemoving: mutation.isLoading, remove }
}

type ReplaceImageState = UploadState
export function useReplaceImage(image: Image | undefined): ReplaceImageState {
  const queryClient = useQueryClient()
  const { upload, abort, status, progress } = useUploadFile()
  // End of Hooks

  const replace = useCallback(
    async (file: File) => {
      if (!image) return
      if (!image.uploadUrl) return
      // Update queryClient with modified image url so images will rerender
      queryClient.setQueryData<API.BookGetSuccess | undefined>(bookKey(image.book), (previous) => {
        if (!previous?.book) return previous
        const img = previous.book.content.images.find((i) => i.imageId === image.imageId)
        if (img) {
          logger.verbose('Resetting web, thumb, original urls so browser will refetch from S3 instead of cache')
          img.web = refreshUrl(img.web)
          img.thumb = refreshUrl(img.thumb)
          img.original = refreshUrl(img.original)
        }
        // Use the "updatedAt" hack described in story-api to force Book to reload.
        return { ...previous, updatedAt: Date.now() }
      })
      upload(image.uploadUrl, addImageExtension(image.extension), file)
    },
    [image, upload, queryClient]
  )
  const handleAbortClick = useCallback(() => {
    abort()
  }, [abort])

  return {
    upload: replace,
    status: status,
    progress,
    abort: handleAbortClick,
  }
}

type UpdateImageState = {
  isLoading: boolean
  update: (image: Image) => Promise<Image>
}
export function useUpdateImage(): UpdateImageState {
  const queryClient = useQueryClient()
  const mutation = useMutation((input: API.ImageUpdateInput) => updateImage(input))
  async function update(image: Image): Promise<Image> {
    try {
      const output = await mutation.mutateAsync({ image: image.toModel() })
      queryClient.setQueryData<API.BookGetSuccess | undefined>(bookKey(image.book), (previous) => {
        if (!previous?.book) return previous
        const { content } = previous.book
        content.images = content.images.map((image) => (image.imageId === output.image.imageId ? output.image : image))
        return previous
      })
      return new Image(image.book, output.image)
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, update }
}

export function updateImage(input: API.ImageUpdateInput): Promise<API.ImageUpdateSuccess> {
  return serverRequest<API.ImageUpdateInput, API.ImageUpdateSuccess>('/image/update', input)
}
function refreshUrl(url: string | undefined): string | undefined {
  if (!url) return undefined
  const urlObject = new URL(url)
  urlObject.searchParams.set('timestamp', String(Date.now()))
  return urlObject.href
}
async function addImage(input: API.ImageAddInput): Promise<API.ImageAddSuccess> {
  return serverRequest<API.ImageAddInput, API.ImageAddSuccess>('/image/add', input)
}

async function removeImage(input: API.ImageRemoveInput): Promise<API.ImageRemoveSuccess> {
  return serverRequest<API.ImageRemoveInput, API.ImageRemoveSuccess>('/image/remove', input)
}
