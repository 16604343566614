import { API } from '@life/model'
import { useMemo } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { serverRequest } from './api'
import { User } from './user'

export type UserState = {
  isLoading: boolean
  error?: API.GetErrors
  user?: User
}
/** Gets the current User. */
export function useUser(): UserState {
  const query = useQuery<API.UserGetSuccess, API.GetErrors>('user', () => getUser(), {
    refetchOnWindowFocus: false,
  })
  // Make sure hook result is stable
  const user = useMemo(() => (query.data ? new User(query.data.user) : undefined), [query.data])
  const error = useMemo(() => query.error ?? undefined, [query.error])
  return {
    ...query,
    error,
    user,
  }
}

export type AddUserState = {
  isLoading: boolean
  error?: API.AddErrors
  user?: User
}
/** Gets the current User and also Adds the User if not registered. */
export function useAddUser(): AddUserState {
  const query = useQuery<API.UserAddSuccess, API.AddErrors>('user', () => addUser(), {
    retry: 1,
  })
  // Make sure hook result is stable
  const user = useMemo(() => (query.data ? new User(query.data.user) : undefined), [query.data])
  const error = useMemo(() => query.error ?? undefined, [query.error])
  return {
    ...query,
    error,
    user,
  }
}

export type UpdateUserState = {
  isLoading: boolean
  update: (user: User) => Promise<void>
}
export function useUpdateUser(): UpdateUserState {
  const queryClient = useQueryClient()
  const mutation = useMutation((input: API.UserUpdateInput) => updateUser(input))
  async function update(user: User): Promise<void> {
    try {
      await mutation.mutateAsync({ user: user.toModel() })
      queryClient.invalidateQueries('user')
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, update }
}

export type RemoveUserState = {
  isLoading: boolean
  remove: (user: User) => Promise<void>
}
export function useRemoveUser(): RemoveUserState {
  const queryClient = useQueryClient()
  const mutation = useMutation((input: API.UserRemoveInput) => removeUser(input))
  async function remove(user: User): Promise<void> {
    try {
      await mutation.mutateAsync({ userId: user.userId })
      queryClient.invalidateQueries('user')
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, remove }
}

function getUser(input: API.UserGetInput = {}): Promise<API.UserGetSuccess> {
  return serverRequest<API.UserGetInput, API.UserGetSuccess>('/user/get', input)
}

// function getUserPublic(input: API.UserPublicInput): Promise<API.UserPublicSuccess> {
//   return serverRequest<API.UserPublicInput, API.UserPublicSuccess>('/user/public', input)
// }

function addUser(): Promise<API.UserAddSuccess> {
  return serverRequest<API.UserAddInput, API.UserAddSuccess>('/user/add')
}

function updateUser(input: API.UserUpdateInput): Promise<API.UserUpdateSuccess> {
  return serverRequest<API.UserUpdateInput, API.UserUpdateSuccess>('/user/update', input)
}

function removeUser(input: API.UserRemoveInput): Promise<API.UserRemoveSuccess> {
  return serverRequest<API.UserRemoveInput, API.UserRemoveSuccess>('/user/remove', input)
}
