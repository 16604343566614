import {
  ButtonBack,
  ButtonRemove,
  ButtonSave,
  classNames,
  HelpText,
  navigateConfirmed,
  NavigationConfirmation,
  NotReady,
  PageHeading,
  PagePadding,
  useErrorNotification,
  useRequiredParam,
} from '@life/components'
import { useBook, useRemoveBook, useUpdateBook } from '@life/frontend-model'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { canEditBook } from '../common'

export function EditBook() {
  type FormFields = {
    slug: string
    title: string
    subtitle: string
    author: string
  }
  const bookSlug = useRequiredParam('bookSlug')
  const { book, isLoading, error } = useBook(bookSlug)
  const navigate = useNavigate()
  const updater = useUpdateBook()
  const remover = useRemoveBook()
  const { showError } = useErrorNotification()

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty },
  } = useForm<FormFields>()
  useEffect(() => {
    reset({ slug: book?.slug, title: book?.title, subtitle: book?.subtitle, author: book?.author })
  }, [book, reset])

  if (isLoading || error || !book || !bookSlug) {
    return <NotReady type="Book" id={bookSlug} isLoading={isLoading} error={error} />
  }

  async function update(fields: FormFields): Promise<void> {
    if (!book) return // should never happen
    try {
      book.slug = fields.slug
      book.title = fields.title
      book.subtitle = fields.subtitle
      book.author = fields.author
      await updater.update(book)
      reset() // clear any 'dirty' flags
      await navigateConfirmed(navigate, '..')
    } catch (error) {
      showError('Error Updating Book', error)
    }
  }

  async function remove(): Promise<void> {
    if (!book) throw new Error('should never happen')
    try {
      await remover.remove(book)
      reset() // clear any 'dirty' flags
      await navigateConfirmed(navigate, '/')
    } catch (error) {
      showError('Error Deleting Book', error)
    }
  }

  const slug = watch('slug')
  return (
    <PagePadding narrow>
      <form onSubmit={handleSubmit(update)} className="space-y-8 divide-y divide-gray-200">
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div>
            <div>
              <PageHeading title={['Book', book.title]}>Edit Book Settings</PageHeading>
            </div>

            <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="title" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Book Title
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    autoComplete="name"
                    placeholder="Book Title"
                    className={classNames(
                      errors.title
                        ? 'text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 ring-red-400 border-red-300'
                        : 'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300',
                      'w-full rounded-md'
                    )}
                    {...register('title', {
                      required: {
                        value: true,
                        message: 'Title is required',
                      },
                      minLength: {
                        value: 5,
                        message: 'Titles should be at least 5 characters long',
                      },
                      maxLength: {
                        value: 30,
                        message: 'Please keep titles less than 30 characters',
                      },
                    })}
                  />
                  <HelpText error={errors.title}>
                    The main title of the book. If you don&apos;t have a title, then just enter your name.
                  </HelpText>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="subtitle" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Subtitle
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    className={classNames(
                      errors.subtitle
                        ? 'text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 ring-red-400 border-red-300'
                        : 'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300',
                      'w-full rounded-md'
                    )}
                    {...register('subtitle')}
                  />
                  <HelpText error={errors.subtitle}>Optional subtitle for the book.</HelpText>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="author" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Author
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    autoComplete="name"
                    placeholder="Author"
                    className={classNames(
                      errors.title
                        ? 'text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 ring-red-400 border-red-300'
                        : 'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300',
                      'w-full rounded-md'
                    )}
                    {...register('author')}
                  />
                  <HelpText error={errors.title}>The book author(s).</HelpText>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="slug" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Website Url
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    autoComplete="name"
                    className={classNames(
                      errors.slug
                        ? 'text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 ring-red-400 border-red-300'
                        : 'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300',
                      'w-full rounded-md'
                    )}
                    {...register('slug', {
                      pattern: {
                        value: /^[a-z][a-z0-9._+-]*$/,
                        message:
                          'Website url must start with a letter and contain only letters, numbers, and these characters . _ + -',
                      },
                      minLength: {
                        value: 5,
                        message: 'Website url must be at least 5 characters',
                      },
                      maxLength: {
                        value: 50,
                        message: 'Website url must not be longer than 50 characters',
                      },
                    })}
                  />
                  <HelpText error={errors.slug}>
                    An optional short URL for the book. <br />
                    {window.location.origin}/book/{slug || 'URL_HERE'}
                  </HelpText>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-4 text-center w-full flex flex-row justify-between">
          <ButtonBack />
          <ButtonSave disabled={!isDirty} clicked={updater.isUpdating} canEdit={canEditBook(book)}>
            Save Book
          </ButtonSave>
        </div>

        <div className="pb-4 pt-16">
          <h2 className="text-red-600 text-2xl font-black">Danger Zone</h2>
          <div className="border rounded-md border-dashed border-red-600 py-6 px-2 text-center">
            <ButtonRemove clicked={remover.isLoading} onClick={remove} canEdit={canEditBook(book)}>
              Delete Book named &ldquo;{book.title}&rdquo;
            </ButtonRemove>
          </div>
        </div>
        <NavigationConfirmation unsavedChanges={isDirty} />
      </form>
    </PagePadding>
  )
}
