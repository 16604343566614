import { API, StoryDate, StoryStatusType } from '@life/model'
import { useMutation, useQueryClient } from 'react-query'
import { serverRequest } from './api'
import { bookKey } from './book-api'
import { Story, UnsavedStory } from './story'

export type AddStoryState = {
  isLoading: boolean
  add: (story: UnsavedStory) => Promise<Story>
}
export function useAddStory(): AddStoryState {
  const queryClient = useQueryClient()
  const mutation = useMutation((input: API.StoryAddInput) => addStory(input))
  async function add(story: UnsavedStory): Promise<Story> {
    try {
      const output = await mutation.mutateAsync({ story: story.toUnsavedModel() })
      queryClient.setQueryData<API.BookGetSuccess | undefined>(bookKey(story.book), (previous) => {
        previous?.book?.content.stories.push(output.story)
        return previous
      })
      return new Story(story.book, output.story)
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, add }
}

type UpdateStoryOptions = {
  title?: string
  status?: StoryStatusType
  occurred?: StoryDate | null
}
export type UpdateStoryState = {
  isLoading: boolean
  update: (story: Story, options?: UpdateStoryOptions) => Promise<Story>
}
export function useUpdateStory(): UpdateStoryState {
  const queryClient = useQueryClient()
  const mutation = useMutation((input: API.StoryUpdateInput) => updateStory(input))
  async function update(story: Story, options?: UpdateStoryOptions): Promise<Story> {
    const request = options
      ? {
          bookId: story.bookId,
          storyId: story.storyId,
          ...options,
        }
      : { story: story.toModel() }
    try {
      const output = await mutation.mutateAsync(request)
      queryClient.setQueryData<API.BookGetSuccess | undefined>(bookKey(story.book), (previous) => {
        if (!previous?.book) return undefined
        previous.book.content.stories = previous.book.content.stories.map((story) =>
          story.storyId === output.story.storyId ? output.story : story
        )
        // There is a problem with react-query and how we construct a Book class instance.
        // react-query does change detection field-by-field, but in useBook we only look to see if
        // the book has changed. Since this won't change the book fields, react-query will return
        // the original instance to useBook, so we won't instantiate a new Book class instance.
        // As an extreme hack, we add a "nonsense" field to the query result here to force react-query
        // to generate a new instance. Then the field is removed in useBook. This seems to work.
        return { ...previous, updatedAt: Date.now() }
      })
      return new Story(story.book, output.story)
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, update }
}

export type RemoveStoryState = {
  isRemoving: boolean
  remove: (story: Story) => Promise<void>
}
export function useRemoveStory(): RemoveStoryState {
  const queryClient = useQueryClient()
  const mutation = useMutation((input: API.StoryRemoveInput) => removeStory(input))
  async function remove(story: Story): Promise<void> {
    try {
      const output = await mutation.mutateAsync({ bookId: story.bookId, storyId: story.storyId })
      queryClient.setQueryData<API.BookGetSuccess | undefined>(bookKey(story.book), (previous) => {
        if (!previous?.book) return undefined
        previous.book.content.stories = previous.book.content.stories.filter(
          (story) => story.storyId !== output.storyId
        )
        return previous
      })
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { isRemoving: mutation.isLoading, remove }
}

function addStory(input: API.StoryAddInput): Promise<API.StoryAddSuccess> {
  return serverRequest<API.StoryAddInput, API.StoryAddSuccess>('/story/add', input)
}

function updateStory(input: API.StoryUpdateInput): Promise<API.StoryUpdateSuccess> {
  return serverRequest<API.StoryUpdateInput, API.StoryUpdateSuccess>('/story/update', input)
}

function removeStory(input: API.StoryRemoveInput): Promise<API.StoryRemoveSuccess> {
  return serverRequest<API.StoryRemoveInput, API.StoryRemoveSuccess>('/story/remove', input)
}
