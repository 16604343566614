import * as Model from '@life/model'
import { Book } from './book'
import { BookItem, UnsavedBookItem } from './book-item'
import { Person } from './person'
import { Story } from './story'

export class UnsavedImage extends UnsavedBookItem {
  /** The original file name */
  name?: string
  /** File extension of the image such as jpg, png, tiff, heic */
  extension: string
  /** Number of bytes of the original file */
  size?: number
  /** Width in pixels of the original file uploaded */
  width?: number
  /** Height in pixels of the original file uploaded */
  height?: number
  /** Date image was created (e.g., photo was taken) */
  when?: Model.DateBreakdown
  /** Where the image was created (e.g., photo was taken) */
  where?: Model.LocationId
  /** Personal notes about image, to aid in organization and navigation */
  notes?: string
  private whoData?: (Model.PersonId | Model.ImagePerson)[]
  private whoCache: Person[] | undefined

  constructor(book: Book, data: Model.UnsavedImage) {
    super(book)
    this.name = data.name
    this.extension = data.extension
    this.size = data.size
    this.width = data.width
    this.height = data.height
    this.when = data.when
    this.whoData = data.who
    this.where = data.where
    this.notes = data.notes
  }

  override get key(): string {
    return this.name ?? 'undefined'
  }

  override toUnsavedModel(): Model.UnsavedImage {
    return {
      bookId: this.bookId,
      name: this.name,
      extension: this.extension,
      size: this.size,
      width: this.width,
      height: this.height,
      when: this.when,
      who: this.whoData,
      where: this.where,
      notes: this.notes,
    }
  }

  static toReadableSize(bytes: number): string {
    if (bytes > 10000000) return Math.round(bytes / 1000000) + ' MB'
    if (bytes > 1000000) return Math.round(bytes / 100000) / 10 + ' MB'
    if (bytes > 1000) return Math.round(bytes / 1000) + ' KB'
    if (bytes > 100) return Math.round(bytes / 100) / 10 + ' KB'
    return bytes + ' B'
  }

  /**
   * Calculates the sizes of an image (width, height, isLandscape).
   * First examines the width and height in the Image. If those are not present
   * then it will calculate from an HTMLImageElement, if provided.
   * Usage:
   *   const imgRef = useRef<HTMLImageElement>(null)
   *   const [width, height, isLandscape] = getImageSize(image, imgRef.current) ?? []
   *   return <img ref={imgRef} src=... />
   * @return [width, height, isLandscape] or undefined if it cannot be computed
   */
  imageSize(imgEl?: HTMLImageElement | null): [number, number, boolean] | undefined {
    let w, h
    if (this.width !== undefined && this.height !== undefined) {
      w = this.width
      h = this.height
    } else if (imgEl) {
      w = imgEl.naturalWidth
      h = imgEl.naturalHeight
    }
    return w !== undefined && h !== undefined ? [w, h, w >= h] : undefined
  }

  /** Who is in the image */
  get who(): Person[] {
    if (!this.whoCache) {
      const ids: Model.PersonId[] = []
      this.whoData?.forEach((w) => {
        if (typeof w === 'string') ids.push(w)
        else ids.push(w.personId)
      })
      this.whoCache = ids.reduce((people, id) => {
        const person = this.book.findPerson(id)
        if (person) people.push(person)
        return people
      }, [] as Person[])
    }
    return this.whoCache
  }
  set who(people: Person[]) {
    this.whoData = people.map((p) => p.personId)
    this.whoCache = undefined
  }

  personLocation(person: Person): Model.ImagePerson['location'] | undefined {
    const imgP = this.whoData?.find((w) => typeof w !== 'string' && w.personId === person.personId)
    return (imgP as Model.ImagePerson | undefined)?.location
  }
  isPersonInImage(person: Person): boolean {
    return !!this.who.find((p) => p.personId === person.personId)
  }
}

export class Image extends UnsavedImage implements BookItem {
  readonly imageId: Model.ImageId
  readonly originalUrl: string
  readonly webUrl: string
  readonly thumbUrl: string
  readonly uploadUrl: string

  constructor(book: Book, data: Model.S3Image) {
    super(book, data)
    this.imageId = data.imageId
    this.originalUrl = data.original ?? ''
    this.webUrl = data.web ?? ''
    this.thumbUrl = data.thumb ?? ''
    this.uploadUrl = data.upload ?? ''
  }

  override get key(): string {
    return this.imageId
  }
  get link(): string {
    return this.book.link + '/photos/' + this.imageId
  }

  get storiesWithImage(): Story[] {
    return this.book.stories.filter((s) => s.isImageInStory(this))
  }

  toModel(): Model.Image {
    return {
      imageId: this.imageId,
      ...this.toUnsavedModel(),
    }
  }
}
