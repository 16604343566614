import { Book, Story } from '@life/frontend-model'
import { useEffect, useState } from 'react'
import { useScrollState } from './ScrollState'
import { ViewChapter } from './ViewSections'

type Props = {
  book: Book
}
export function BookReader({ book }: Props) {
  const { currentSection, handleScroll } = useScrollState()
  const [chapters, setChapters] = useState<Story[]>([])
  useEffect(() => {
    // Group beforeToc and afterToc in one group (Front Matter)
    if (currentSection === 'beforeToc' || currentSection === 'afterToc')
      setChapters([...book.beforeToc, ...book.afterToc])
    else setChapters(book[currentSection])
  }, [book, currentSection])

  return (
    <div onScroll={handleScroll} className="flex-1 w-full h-full max-w-prose px-2 overflow-y-auto">
      {chapters.map((story) => (
        <ViewChapter key={story.storyId} story={story} />
      ))}
    </div>
  )
}
