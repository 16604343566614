import * as Model from '@life/model'
import { isDefined } from '@life/model'
import { Image } from './image'
import { Location } from './location'
import { Person } from './person'
import { Story } from './story'

export type TocSection = 'beforeToc' | 'afterToc' | 'chapters' | 'appendix'

export class UnsavedBook {
  slug: string
  title: string
  subtitle?: string
  author?: string
  maxCollaborators?: number

  constructor(data: Model.UnsavedBook) {
    this.slug = data.slug
    this.title = data.title
    this.subtitle = data.subtitle
    this.author = data.author
    this.maxCollaborators = data.maxCollaborators
  }

  get key(): string {
    return this.title
  }

  toUnsavedModel(): Model.UnsavedBook {
    return {
      slug: this.slug,
      title: this.title,
      subtitle: this.subtitle,
      author: this.author,
      maxCollaborators: this.maxCollaborators,
    }
  }
}

export class BookInfo extends UnsavedBook {
  readonly bookId: Model.BookId

  constructor(data: Model.Book) {
    super(data)
    this.bookId = data.bookId
  }

  override get key(): string {
    return this.bookId
  }

  toModel(): Model.Book {
    if (!this.bookId) throw new Error('Must be saved before calling toModel')
    return {
      ...this.toUnsavedModel(),
      bookId: this.bookId,
    }
  }
}

export class BookView extends BookInfo {
  role?: Model.CollaboratorRole
  readonly lastAccessed: number

  constructor(data: Model.BookView) {
    super(data)
    this.role = data.role
    this.lastAccessed = data.lastAccessed || 0
  }
}

export class Book extends BookView {
  toc: Model.TableOfContents
  readonly stories: readonly Story[]
  readonly images: readonly Image[]
  readonly people: readonly Person[]
  readonly locations: readonly Location[]

  constructor(data: Model.FullBook) {
    super({ ...data.info, ...data.collaborator })
    this.toc = data.content.toc
    this.stories = data.content.stories.map((s) => new Story(this, s))
    this.images = data.content.images.map((i) => new Image(this, i))
    this.people = data.content.people.map((p) => new Person(this, p))
    this.locations = data.content.locations.map((i) => new Location(this, i))
  }

  findStory(storyId: Model.StoryId | undefined): Story | undefined {
    return storyId ? this.stories.find((story) => story.storyId === storyId) : undefined
  }

  findPerson(personId: Model.PersonId | undefined): Person | undefined {
    return personId ? this.people.find((person) => person.personId === personId) : undefined
  }

  findLocation(locationId: Model.LocationId | undefined): Location | undefined {
    return locationId ? this.locations.find((location) => location.locationId === locationId) : undefined
  }

  findImage(imageId: Model.ImageId | undefined): Image | undefined {
    return imageId ? this.images.find((image) => image.imageId === imageId) : undefined
  }

  get link(): string {
    return '/book/' + (this.slug || this.bookId)
  }

  get beforeToc(): Story[] {
    const ids = this.toc.beforeToc ?? []
    return ids.map((id) => this.stories.find((story) => story.storyId === id)).filter(isDefined)
  }

  get afterToc(): Story[] {
    const ids = this.toc.afterToc ?? []
    return ids.map((id) => this.stories.find((story) => story.storyId === id)).filter(isDefined)
  }

  get chapters(): Story[] {
    const ids = this.toc.chapters2 ?? []
    return ids.map((id) => this.stories.find((story) => story.storyId === id)).filter(isDefined)
  }

  get appendix(): Story[] {
    const ids = this.toc.appendix2 ?? []
    return ids.map((id) => this.stories.find((story) => story.storyId === id)).filter(isDefined)
  }

  get allChapters(): Story[] {
    const ids = this.getAllChapterIds()
    return this.stories.filter((story) => ids.has(story.storyId))
  }

  findStorySection(storyId: Model.StoryId): TocSection | undefined {
    if (this.beforeToc.some((s) => s.containsStory(storyId))) return 'beforeToc'
    if (this.afterToc.some((s) => s.containsStory(storyId))) return 'afterToc'
    if (this.chapters.some((s) => s.containsStory(storyId))) return 'chapters'
    if (this.appendix.some((s) => s.containsStory(storyId))) return 'appendix'
    return undefined
  }

  private getAllChapterIds(): Set<Model.StoryId> {
    const { toc } = this
    const chapters = new Set<Model.StoryId>()
    function add(ids?: Model.StoryId[]): void {
      if (!ids) return
      ids.forEach((id) => chapters.add(id))
    }
    add(toc.beforeToc)
    add(toc.afterToc)
    add(toc.chapters2)
    add(toc.appendix2)
    return chapters
  }
}
