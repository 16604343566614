import { classNames, ExpandIcon } from '@life/components'
import { Book, RenderElementProps } from '@life/frontend-model'
import { Link } from 'react-router-dom'
import { StoryPreview } from '../reader/StoryReader'

type Props = RenderElementProps & {
  selected: boolean
  book: Book
}

export function StorySubstory(props: Props): JSX.Element {
  const { element, attributes, children, book, ...fxn } = props
  if (element.type !== 'substory') throw new Error('Not a substory')

  const story = book.findStory(element.id)
  if (!story) return <></>
  return (
    <div {...attributes}>
      <div
        contentEditable={false}
        className={classNames(
          'border-4',
          fxn.selected ? ' border-yellow-300' : 'border-transparent',
          'relative max-w-prose clear-both'
        )}
      >
        <StoryPreview book={book} story={story} enableLinks={false} />
        <div className="flex justify-end">
          <Link to={story.link} className="float-right flex hover:text-cyan-500 text-cyan-600 cursor-pointer">
            <span className="hover:underline text-sm">Go to Substory</span>
            <ExpandIcon className="w-4" />
          </Link>
        </div>
      </div>
      {children}
    </div>
  )
}
