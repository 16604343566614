import {
  ButtonAdd,
  ButtonEdit,
  dateToString,
  NotReady,
  PageHeading,
  PagePadding,
  Spinner,
  useErrorNotification,
  useRequiredParam,
} from '@life/components'
import { Image, Person, Story, useBook, useUpdatePerson } from '@life/frontend-model'
import { isEmpty } from '@life/model'
import { useState } from 'react'
import { canEditBook } from '../common'
import { ImageList } from '../image'
import { StoryList } from '../story'
import { PersonImage } from './PersonImage'
import { PersonMenu } from './PersonMenu'

export function PersonPage(): JSX.Element {
  const [bookSlug, personId] = useRequiredParam(['bookSlug', 'personId'])
  const { book, isLoading, error } = useBook(bookSlug)
  const [selectingPrimary, setSelectingPrimary] = useState(false)
  const updater = useUpdatePerson()
  const { showError } = useErrorNotification()
  // End of Hooks

  if (isLoading || error || !book) return <NotReady type="Book" id={bookSlug} isLoading={isLoading} error={error} />
  const person = book.findPerson(personId)
  if (!person) return <NotReady type="Person" id={personId} notFound />

  const primaryImage = book.findImage(person.imageId)
  const images = person.imagesWithPerson
  const stories = person.storiesWithPerson

  async function setPrimary(image: Image): Promise<void> {
    if (!person) return // should never happen
    try {
      setSelectingPrimary(false)
      person.imageId = image.imageId
      await updater.update(person)
    } catch (error) {
      showError('Error setting primary photo', error)
    }
  }

  return (
    <PagePadding wide>
      <PageHeading
        title={['Person', person.formalName]}
        right={
          <div className="flex flex-row items-center gap-4">
            <ButtonEdit to="edit" canEdit={canEditBook(book)} />
            <PersonMenu person={person} />
          </div>
        }
      >
        {person.formalName}
      </PageHeading>
      <div className="flex flex-row">
        <div className="w-48 text-center">
          <PersonImage image={primaryImage} />
          {images.length > 0 && (
            <button
              className="cursor-pointer text-sm text-indigo-700 hover:underline"
              onClick={() => {
                setSelectingPrimary(!selectingPrimary)
              }}
            >
              {selectingPrimary ? 'Cancel' : 'Select'}
            </button>
          )}
        </div>
        <div className="p-6">
          {person.nickName && <p>Nickname: {person.nickName}</p>}
          {person.otherLastNames && <p>Other last name(s): {person.otherLastNamesDisplay}</p>}
          {person.birthDate && <p>Born: {dateToString(person.birthDate)}</p>}
          {person.deathDate && <p>Died: {dateToString(person.deathDate)}</p>}
        </div>
      </div>
      <div>
        <ImagesOfPerson
          person={person}
          images={images}
          choosing={selectingPrimary}
          saving={updater.isLoading}
          setPrimary={setPrimary}
        />
        <StoriesOfPerson person={person} stories={stories} />
      </div>
    </PagePadding>
  )
}

type ImagesProps = {
  person: Person
  images: Image[]
  choosing: boolean
  saving: boolean
  setPrimary: (image: Image) => void
}
function ImagesOfPerson({ person, images, choosing, saving, setPrimary }: ImagesProps): JSX.Element {
  if (isEmpty(images)) {
    return (
      <div>
        <h3 className="text-lg py-4 font-bold">Photos</h3>
        <ButtonAdd to="../photos/_add" canEdit={canEditBook(person.book)}>
          Add a Photo
        </ButtonAdd>
      </div>
    )
  }
  if (saving) {
    return (
      <div>
        <h3 className="text-lg py-4 font-bold">Photos</h3>
        <Spinner className="mr-2 h-5 w-5" aria-hidden="true" />
      </div>
    )
  }

  return (
    <div>
      <h3 className="text-lg py-4 font-bold">Photos</h3>
      {choosing && <div className="py-2 text-bold italic">Select Primary Image for {person.formalName}</div>}
      <div className="flex flex-wrap">
        <ImageList mode={choosing ? 'choose' : 'view'} book={person.book} images={images} onChoose={setPrimary} />
      </div>
    </div>
  )
}

type StoresProps = {
  person: Person
  stories: Story[]
}
function StoriesOfPerson({ person, stories }: StoresProps): JSX.Element {
  if (isEmpty(stories)) {
    return (
      <div>
        <h3 className="text-lg py-4 font-bold">Stories</h3>
        <ButtonAdd to="../stories/_add" canEdit={canEditBook(person.book)}>
          Add a Story
        </ButtonAdd>
      </div>
    )
  }
  return (
    <div>
      <h3 className="text-lg py-4 font-bold">Stories</h3>
      <StoryList book={person.book} stories={stories} />
    </div>
  )
}
