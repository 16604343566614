import {
  dateToString,
  ItemList,
  ItemListMode,
  PersonIconWithToolTip,
  SortDef,
  StatusIconWithToolTip,
} from '@life/components'
import { Book, Story } from '@life/frontend-model'
import { useItemNavigation } from '../../common'
import { searchStories } from '../../search'
import { sortStories, StorySortType } from '../util'

const sortDefs: SortDef<StorySortType>[] = [
  { sortType: 'date', description: 'Date' },
  { sortType: 'title', description: 'Title' },
  { sortType: 'status', description: 'Status' },
  { sortType: 'no-people', description: 'No. People' },
]

type Props = {
  mode?: ItemListMode
  book: Book
  stories: readonly Story[]
  initialFilter?: string
  onSelect?: (story: Story) => void
  isSelected?: (story: Story) => boolean
  onChoose?: (story: Story) => void
  noHeader?: boolean
}
export function StoryList({
  mode,
  book,
  stories,
  initialFilter,
  onSelect,
  isSelected,
  onChoose,
  noHeader,
}: Props): JSX.Element {
  const { navToStory } = useItemNavigation(book)

  function filterSort(stories: readonly Story[], sortType: StorySortType | undefined, filter?: string): Story[] {
    const newStories = filter ? searchStories(stories, filter) : [...stories]
    if (sortType) sortStories(newStories, sortType)
    return newStories
  }

  return (
    <ItemList
      mode={mode}
      items={stories}
      prefsKey="storyList"
      onChoose={onChoose}
      onSelect={onSelect}
      isSelected={isSelected}
      onNavigate={navToStory}
      sortDefs={sortDefs}
      doFilterSort={filterSort}
      initialFilter={initialFilter}
      noHeader={noHeader}
      searchHelp={<StorySearchHelp />}
    >
      {(viewType, story) => (
        <>
          {viewType === 'grid' && <GridStory story={story} />}
          {viewType === 'list' && <ListStory story={story} />}
        </>
      )}
    </ItemList>
  )
}

type ItemProps = {
  story: Story
}

function GridStory({ story }: ItemProps): JSX.Element {
  return (
    <div className="flex flex-row p-2 items-center bg-white border rounded-xl shadow hover:shadow-md m-1">
      <div className="flex-1 flex flex-col pl-2">
        <h3 className="text-gray-800 line-clamp-2">{story.title}</h3>
        <div className="pt-1 flex justify-between">
          <p className="text-xs text-gray-500">{dateToString(story.occurred)}</p>
          <IconBar story={story} />
        </div>
      </div>
    </div>
  )
}

function ListStory({ story }: ItemProps): JSX.Element {
  return (
    <div className="flex p-4 justify-between items-center space-x-2 bg-white border rounded-xl shadow hover:shadow-md">
      <h3 className="text-gray-800 text-left space-x-2">{story.title}</h3>
      <div className="flex gap-16">
        <p className="text-xs text-gray-500 text-right">{dateToString(story.occurred)}</p>
        <IconBar story={story} />
      </div>
    </div>
  )
}

type IconBarProps = {
  story: Story
}
function IconBar({ story }: IconBarProps): JSX.Element {
  const status = story.status
  const numPeople = story.people.length
  return (
    <div className="flex items-center">
      <StatusIconWithToolTip status={status} />
      <PersonIconWithToolTip count={numPeople} />
    </div>
  )
}

function StorySearchHelp(): JSX.Element {
  return (
    <>
      <p>Search stories by title, content, or year.</p>
      <p className="py-2">Advanced search:</p>
      <ul className="pl-8 list-disc">
        <li>&lsquo;status:&rsquo; searches by status; e.g., status:idea</li>
        <li>&lsquo;people:&rsquo; searches number of people in the story; e.g., people:0</li>
        <li>&lsquo;photos:&rsquo; searches number of photos in the story; e.g., photos:0</li>
      </ul>
    </>
  )
}
