import { API, FullBook, isStoryImage, Logger, Slug, StoryImage, TableOfContents } from '@life/model'
import { useMemo } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { serverRequest, serverRequestNoAuth } from './api'
import { Book, BookInfo, BookView, UnsavedBook } from './book'
import { ONE_MINUTE } from './time'

const logger = new Logger('api-book')

const bookInfoKey = (slug: Slug) => `BOOK_INFO:${slug}`
const bookIdKey = (slug: Slug) => `BOOK:${slug}`
export const bookKey = (book: BookView) => bookIdKey(book.slug || book.bookId || 'undefined')
export const booksKey = 'BOOK_LIST'

export type BookState = {
  isLoading: boolean
  error?: API.GetErrors
  book?: Book
}
export function useBook(slug: Slug): BookState {
  if (!slug) logger.error('undefined or empty slug passed to useBook')
  const query = useQuery<API.BookGetSuccess, API.GetErrors>(bookIdKey(slug), () => getBook({ id: slug }), {
    retry: 1,
    staleTime: 15 * ONE_MINUTE,
  })

  // Make sure hook result is stable
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- for logging
  logger.verbose('useBook(updatedAt)', (query.data?.book as any)?.updatedAt)
  const book = useMemo(() => {
    logger.verbose('useBook -> useMemo(book)')
    // See story-api for explanation of this next line.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- use 'any' so we can delete the nonsense field
    delete (query.data as any)?.updatedAt
    return query.data?.book ? new Book(fixLegacy(query.data.book)) : undefined
  }, [query.data])
  const error = useMemo(() => query.error ?? undefined, [query.error])
  return {
    ...query,
    error,
    book,
  }
}
function fixLegacy(book: FullBook): FullBook {
  // Copy image caption to Story 'image' element
  book.content.images.forEach((image) => {
    const caption = (image as { caption?: string }).caption
    if (caption) {
      book.content.stories.forEach((s) => {
        const imageEls = s.content
          .filter((el) => (el as StoryImage).type === 'image' && (el as StoryImage).id === image.imageId)
          .filter(isStoryImage)
        imageEls.forEach((img) => {
          if (!img.caption) img.caption = caption
        })
      })
    }
  })
  // Copy image caption to notes
  book.content.images.forEach((i) => {
    const legacy = i as { caption?: string }
    if (legacy.caption && !i.notes) {
      i.notes = legacy.caption
      delete legacy.caption
    }
  })
  logger.verbose('fixed book', book)
  return book
}

export type BookInfoState = {
  isLoading: boolean
  error?: API.GetErrors
  book?: BookInfo
}
export function useBookInfo(slug: Slug): BookInfoState {
  if (!slug) logger.error('undefined or empty slug passed to useBook')
  const query = useQuery<API.BookInfoSuccess, API.GetErrors>(bookInfoKey(slug), () => getBookInfo({ slug }), {
    retry: 1,
    staleTime: 15 * ONE_MINUTE,
  })

  // Make sure hook result is stable
  const book = useMemo(() => {
    logger.verbose('useBookInfo -> useMemo(book)')
    return query.data?.book ? new BookInfo(query.data.book) : undefined
  }, [query.data])
  const error = useMemo(() => query.error ?? undefined, [query.error])
  return {
    ...query,
    error,
    book,
  }
}

export type BooksState = {
  isLoading: boolean
  error?: API.ListErrors
  list?: BookView[]
}
export function useBooks(): BooksState {
  const query = useQuery<API.BookListSuccess, API.ListErrors>(booksKey, async () => {
    const result = await listBooks()
    if (result.type === 'success') {
      result.list.sort((a, b) => a.title.localeCompare(b.title))
      return result
    }
    throw result
  })
  // Make sure hook result is stable
  const list = useMemo(() => (query.data ? query.data.list.map((b) => new BookView(b)) : undefined), [query.data])
  const error = useMemo(() => query.error ?? undefined, [query.error])
  return {
    ...query,
    error,
    list,
  }
}

export type AddBookState = {
  isLoading: boolean
  add: (book: UnsavedBook) => Promise<BookView>
}
export function useAddBook(): AddBookState {
  const mutation = useMutation((input: API.BookAddInput) => addBook(input))
  async function add(book: UnsavedBook): Promise<BookView> {
    try {
      const result = await mutation.mutateAsync({ book: book.toUnsavedModel() })
      return new BookView(result.book)
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, add }
}

export type UpdateBookState = {
  isUpdating: boolean
  update: (book: BookView) => Promise<BookView>
}
export function useUpdateBook(): UpdateBookState {
  const queryClient = useQueryClient()
  const mutation = useMutation((input: API.BookUpdateInput) => updateBook(input))
  async function update(book: BookView): Promise<BookView> {
    try {
      const output = await mutation.mutateAsync({ book: book.toModel() })
      queryClient.setQueryData<API.BookGetSuccess | undefined>(bookKey(book), (previous) => {
        if (!previous?.book) return previous
        previous.book.info = {
          ...previous.book.info,
          ...output.book,
        }
        return previous
      })
      return new BookView(output.book)
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, update, isUpdating: mutation.isLoading }
}

export type RemoveBookState = {
  isLoading: boolean
  remove: (book: BookView) => Promise<void>
}
export function useRemoveBook(): RemoveBookState {
  const queryClient = useQueryClient()
  const mutation = useMutation((input: API.BookRemoveInput) => removeBook(input))
  async function remove(book: BookView): Promise<void> {
    try {
      await mutation.mutateAsync({ bookId: book.bookId })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Use `any` so we can set previous.book = undefined
      queryClient.setQueryData<API.BookGetSuccess | undefined>(bookKey(book), (previous: any) => {
        if (!previous) return previous
        previous.book = undefined
        return previous
      })
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, remove }
}

export type UpdateTocState = {
  isLoading: boolean
  update: (book: BookView, toc: TableOfContents) => Promise<void>
}
export function useUpdateToc(): UpdateTocState {
  const queryClient = useQueryClient()
  const mutation = useMutation((input: API.BookTocUpdateInput) => updateTableOfContents(input))
  async function update(book: BookView, toc: TableOfContents): Promise<void> {
    try {
      await mutation.mutateAsync({ bookId: book.bookId, toc })
      queryClient.setQueryData<API.BookGetSuccess | undefined>(bookKey(book), (previous) => {
        if (!previous) return previous
        previous.book.content.toc = toc
        return previous
      })
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, update }
}

function listBooks(): Promise<API.BookListOutput> {
  return serverRequest<API.BookListInput, API.BookListSuccess>('/book/list')
}

function getBook(input: API.BookGetInput): Promise<API.BookGetSuccess> {
  return serverRequest<API.BookGetInput, API.BookGetSuccess>('/book/get', input)
}

function getBookInfo(input: API.BookInfoInput): Promise<API.BookInfoSuccess> {
  return serverRequestNoAuth<API.BookInfoInput, API.BookInfoSuccess>('/book/info', input)
}

function addBook(input: API.BookAddInput): Promise<API.BookAddSuccess> {
  return serverRequest<API.BookAddInput, API.BookAddSuccess>('/book/add', input)
}

function updateBook(input: API.BookUpdateInput): Promise<API.BookUpdateSuccess> {
  return serverRequest<API.BookUpdateInput, API.BookUpdateSuccess>('/book/update', input)
}

function removeBook(input: API.BookRemoveInput): Promise<API.BookRemoveSuccess> {
  return serverRequest<API.BookRemoveInput, API.BookRemoveSuccess>('/book/remove', input)
}

function updateTableOfContents(input: API.BookTocUpdateInput): Promise<API.BookTocUpdateSuccess> {
  return serverRequest<API.BookTocUpdateInput, API.BookTocUpdateSuccess>('/book/toc/update', input)
}
