import { API, BookId, StoryId } from '@life/model'
import { useMemo } from 'react'
import { useQuery, UseQueryResult } from 'react-query'
import { serverBaseUrl, serverRequest } from './api'
import { Book } from './book'
import { PdfInfo } from './pdfinfo'

const pdfInfoKey = (bookId: BookId) => `BOOK_PDF:${bookId}`

export type BookPdfState = {
  isLoading: boolean
  error?: API.GetErrors
  pdfInfo?: PdfInfo
  refetch: UseQueryResult<API.BookPdfGetSuccess, API.GetErrors>['refetch']
}

export function useGetPdfInfo(book: Book): BookPdfState {
  const query = useQuery<API.BookPdfGetSuccess, API.GetErrors>(
    pdfInfoKey(book.bookId),
    () => getPdfBook({ bookId: book.bookId }),
    { retry: 1 }
  )
  // Make sure hook result is stable
  const pdfInfo = useMemo(() => (query.data ? new PdfInfo(book, query.data.pdfInfo) : undefined), [book, query.data])
  const error = useMemo(() => query.error ?? undefined, [query.error])
  return {
    ...query,
    error,
    pdfInfo,
  }
}

async function getPdfBook(input: API.BookPdfGetInput): Promise<API.BookPdfGetSuccess> {
  return serverRequest<API.BookPdfGetInput, API.BookPdfGetSuccess>('/book/pdf/get', input)
}

export async function createPdfBookInS3(book: Book): Promise<PdfInfo> {
  const result = await serverRequest<API.BookPdfCreateInput, API.BookPdfCreateSuccess>('/book/pdf/create', {
    bookId: book.bookId,
  })
  return new PdfInfo(book, result.pdfInfo)
}

/**
 * call the backend to get the temporary preview url for this story
 */
export async function getPdfPreviewUrl(bookId: BookId, storyId: StoryId): Promise<string> {
  const response = await serverRequest<API.BookPdfPreviewUrlInput, API.BookPdfPreviewUrlSuccess>(
    '/book/pdf/preview/url',
    { bookId, storyId }
  )
  return `${serverBaseUrl}${response.uri}`
}
