import { Switch } from '@headlessui/react'
import {
  BookIcon,
  ButtonCancel,
  ButtonSave,
  ComputerIcon,
  DatesForm,
  Input,
  LifeDialog,
  NotReady,
  PageHeading,
  PagePadding,
  SupportIcon,
  useErrorNotification,
  useRequiredParam,
} from '@life/components'
import { Book, Person, UnsavedPerson, useAddPerson, useBook } from '@life/frontend-model'
import { DateBreakdown } from '@life/model'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { splitNames } from './util'

export function AddPerson() {
  const bookSlug = useRequiredParam('bookSlug')
  const { book, isLoading, error } = useBook(bookSlug)
  const navigate = useNavigate()

  if (isLoading || error || !book || !bookSlug) {
    return <NotReady type="Book" id={bookSlug} isLoading={isLoading} error={error} />
  }

  function handleAdd(person: Person): void {
    navigate('../people/' + person.personId)
  }

  return (
    <PagePadding narrow>
      <PageHeading title="Add Person">Add Person</PageHeading>
      <div className="pt-4">
        <AddPersonForm book={book} onAdd={handleAdd} />
      </div>
    </PagePadding>
  )
}

type FormProps = {
  book: Book
  onAdd: (person: Person) => void
  onClose?: () => void
  name?: string
}

export function AddPersonForm({ book, onAdd, onClose, name = '' }: FormProps): JSX.Element {
  type FormFields = { lastName: string; givenNames: string; birthDate: DateBreakdown }
  const adder = useAddPerson()
  const { showError } = useErrorNotification()
  const [birthDateError, setBirthDateError] = useState<string>()
  const [addAnother, setAddAnother] = useState(false)
  const [givenNames, lastName] = splitNames(name)
  const form = useForm<FormFields>({
    defaultValues: {
      lastName,
      givenNames,
    },
  })

  // Since this can be used inside a dialog that is inside another form, we need to stop
  // propagation of the Submit event.
  function handleSubmitWithoutPropagation(e: React.FormEvent): void {
    e.preventDefault()
    e.stopPropagation()
    form.handleSubmit(add)(e)
  }

  async function add(fields: FormFields): Promise<void> {
    try {
      const person = new UnsavedPerson(book, {
        bookId: book.bookId,
        lastName: fields.lastName,
        givenNames: fields.givenNames,
        birthDate: fields.birthDate,
        status: 'stub',
      })
      const saved = await adder.add(person)
      if (addAnother) {
        form.reset()
      } else {
        onAdd(saved)
        onClose && onClose()
      }
    } catch (error) {
      showError('Error Adding Person', error)
    }
  }

  const { register, control, formState } = form

  return (
    <div className="max-w-prose">
      <form key="add-person" onSubmit={handleSubmitWithoutPropagation}>
        <Input
          {...register('givenNames', {
            required: {
              value: true,
              message: 'Given name is required',
            },
          })}
          label="Given name(s)"
          placeholder="Given names"
          help="Enter the given name or names of the person"
          error={formState.errors.givenNames}
        />
        <Input
          {...register('lastName', {
            required: {
              value: true,
              message: 'Last name is required',
            },
          })}
          label="Last name"
          placeholder="Last name"
          help="Enter the last name or family name of the person. You can choose married or maiden name. This is used to sort people in your book's index."
          autoComplete="name"
          error={formState.errors.lastName}
        />
        <label htmlFor="birthDate" className="block text-sm font-bold text-gray-700 mt-6">
          Birth Date
        </label>
        <Controller
          control={control}
          name="birthDate"
          render={({ field: { onChange, value } }) => (
            <DatesForm
              types={['breakdown']}
              optional
              defaultValue={value}
              onChange={(value, error) => {
                onChange(value)
                setBirthDateError(error)
              }}
            />
          )}
        />
        {birthDateError && <div className="mt-1 pl-3 text-sm text-red-500">{birthDateError}</div>}

        {!onClose && (
          <Switch.Group>
            <div className="flex items-center mt-8">
              <Switch.Label className="mr-2 text-gray-700 text-sm">Add Another Person</Switch.Label>
              <Switch
                checked={addAnother}
                onChange={setAddAnother}
                className={`${
                  addAnother ? 'bg-indigo-600' : 'bg-gray-200'
                } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span
                  className={`${
                    addAnother ? 'translate-x-6' : 'translate-x-1'
                  } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                />
              </Switch>
            </div>
          </Switch.Group>
        )}

        <div className="mt-8 flex justify-between p-1">
          {onClose && <ButtonCancel onClick={onClose}></ButtonCancel>}
          <ButtonSave clicked={adder.isLoading}>{addAnother ? 'Save + Add Another' : 'Add Person'}</ButtonSave>
        </div>
      </form>
      {onClose ? null : <AddPersonHelpText />}
    </div>
  )
}

type AddDialogProps = FormProps & {
  key: string // Trick to force dialog to reset state when it's opened. Should be set to String(isOpen)
  isOpen: boolean
  onClose: () => void
}

export function AddPersonDialog({ onClose, ...props }: AddDialogProps): JSX.Element {
  const shortcuts = { Escape: onClose }
  return (
    <LifeDialog title="Add Person" onClose={onClose} shortcuts={shortcuts} {...props}>
      <LifeDialog.Content>
        <AddPersonForm onClose={onClose} {...props} />
      </LifeDialog.Content>
    </LifeDialog>
  )
}

function AddPersonHelpText(): JSX.Element {
  return (
    <div className="text-gray-600">
      <h2 className="flex items-center mt-10 text-lg font-bold text-gray-600">
        <SupportIcon className="h-8" />
        Need Help?
      </h2>
      <p className="my-5">
        Adding people to your story will help to connect your stories together. People you add to your book, will
        automatically be added to the <BookIcon className="h-5 inline" /> physical book&rsquo;s Index and create
        <span className="underline px-1">hyperlinks</span>on the <ComputerIcon className="h-5 inline mx-1" />
        web version.
      </p>
      <p className="my-5">
        Consider adding anyone you mention in a story or you know in a photograph. This may include the following:
      </p>
      <ul className="pl-5 list-disc list-inside leading-relaxed">
        <li>You</li>
        <li>Your parents and grandparents</li>
        <li>Your descendents</li>
        <li>Your descendents&rsquo; spouses and stepchildren</li>
        <li>Friends who made a notable difference in your life</li>
        <li>Coworkers who played a key role in your career</li>
        <li>Mentors, coaches, and religious leaders who changed your life</li>
      </ul>
    </div>
  )
}
