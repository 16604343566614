import {
  ButtonCancel,
  ButtonSave,
  compareDatesWithRanges,
  NavigationConfirmation,
  NotReady,
  PageHeading,
  PagePadding,
  useErrorNotification,
  useRequiredParam,
} from '@life/components'
import { Story, useBook, useUpdateToc } from '@life/frontend-model'
import { isDefined, isEmpty, StoryId } from '@life/model'
import { useEffect, useState } from 'react'
import { ChapterGroup } from './ChapterGroup'
import { GroupHeading } from './GroupHeading'
import { NewStoriesList } from './NewStoriesList'

export function ChapterEditor(): JSX.Element {
  const [bookSlug] = useRequiredParam(['bookSlug'])
  const { book, isLoading, error } = useBook(bookSlug)
  const { isLoading: isUpdating, update } = useUpdateToc()
  const { showError } = useErrorNotification()
  const [selectedStory, selectStory] = useState<Story>()
  const [beforeToc, setBeforeToc] = useState<Story[]>([])
  const [afterToc, setAfterToc] = useState<Story[]>([])
  const [chapters, setChapters] = useState<Story[]>([])
  const [appendix, setAppendix] = useState<Story[]>([])
  const [dirty, setDirty] = useState(false)
  const [cancel, setCancel] = useState(false)
  useEffect(() => {
    const stories = book?.stories.map((s) => s.toModel())
    const toc = book?.toc
    if (!stories) return
    if (!toc) return
    function getStories(ids?: StoryId[]): Story[] {
      if (!book) return []
      if (isEmpty(ids)) return []
      return ids.map((id) => book.findStory(id)).filter(isDefined)
    }

    setBeforeToc(getStories(toc.beforeToc))
    setAfterToc(getStories(toc.afterToc))
    setChapters(getStories(toc.chapters2))
    setAppendix(getStories(toc.appendix2))
    if (cancel) {
      setDirty(false)
      setCancel(false)
    }
  }, [book, cancel])
  // End of Hooks

  if (isLoading || error || !book) return <NotReady type="Book" id={bookSlug} isLoading={isLoading} error={error} />

  const allChapters = [...beforeToc, ...afterToc, ...chapters, ...appendix]
  const storiesToAdd = book.stories.filter((story) => {
    if (story.parent) return false

    const alreadyAdded = allChapters.find((chapter) => {
      if (chapter.storyId === story.storyId) return true
    })
    return !alreadyAdded
  })
  storiesToAdd.sort((a, b) => compareDatesWithRanges(a.occurred, b.occurred))

  function toIds(stories: Story[]): StoryId[] {
    return stories.map((s) => s.storyId)
  }

  return (
    <PagePadding>
      <PageHeading title="Outline">Outline</PageHeading>

      <div className="py-2 flex flex-col-reverse sm:flex-row sm:space-x-10">
        <div className="flex-1">
          <div className="pb-2 flex flex-row gap-6">
            <ButtonSave
              clicked={isUpdating}
              disabled={!dirty}
              onClick={async () => {
                try {
                  await update(book, {
                    beforeToc: toIds(beforeToc),
                    afterToc: toIds(afterToc),
                    chapters2: toIds(chapters),
                    appendix2: toIds(appendix),
                  })
                  setDirty(false)
                } catch (error) {
                  showError('Error Saving Table of Contents', error)
                }
              }}
            />
            <ButtonCancel disabled={!dirty} onClick={() => setCancel(true)} />
          </div>
          <GroupHeading
            title="Front Matter"
            help="Any content to include before the main body of your book. Examples may include Dedication, Table of Contents, Foreward, Preface, and Acknowledgements. Pages are numbered i, ii, iii, etc."
            showHelp={isEmpty([...beforeToc, ...afterToc])}
          />
          <ChapterGroup
            chapters={beforeToc}
            setChapters={(c) => {
              setDirty(true)
              setBeforeToc(c)
            }}
            selectedStory={selectedStory}
            selectStory={selectStory}
          />
          <div className="w-full pb-3 pl-3">
            <div className="flex space-x-3 text-lg">
              <div>{beforeToc.length + 1}</div>
              <div>Table of Contents</div>
            </div>
          </div>
          <ChapterGroup
            chapters={afterToc}
            setChapters={(c) => {
              setDirty(true)
              setAfterToc(c)
            }}
            selectedStory={selectedStory}
            selectStory={selectStory}
            startingNumber={beforeToc.length + 2}
          />
          <GroupHeading
            title="Body"
            help="Include all chapters here. Page numbers start from Chapter 1"
            showHelp={isEmpty(chapters)}
          />
          <ChapterGroup
            chapters={chapters}
            setChapters={(c) => {
              setDirty(true)
              setChapters(c)
            }}
            selectedStory={selectedStory}
            selectStory={selectStory}
          />
          <GroupHeading
            title="Back Matter"
            help="Include any extra chapters here that aren't part of the main story such as an Appendix, Notes, Photos, or Bibliography"
            showHelp={isEmpty(appendix)}
          />
          <ChapterGroup
            chapters={appendix}
            setChapters={(c) => {
              setDirty(true)
              setAppendix(c)
            }}
            selectedStory={selectedStory}
            selectStory={selectStory}
            startingNumber={chapters.length + 1}
          />
        </div>
        <NewStoriesList selectedStory={selectedStory} stories={storiesToAdd} selectStory={selectStory} />
      </div>
      <NavigationConfirmation unsavedChanges={dirty} />
    </PagePadding>
  )
}
