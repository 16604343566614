import { classNames, dateToString, EditIcon, Tooltip, useErrorNotification, WithChildren } from '@life/components'
import { Person, Story, useUpdateStory } from '@life/frontend-model'
import { StoryDate, StoryStatusType } from '@life/model'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { PersonLink } from '../../person'
import { OccurredDialog } from '../OccurredDialog'
import { StatusMenu } from '../StatusMenu'
import { Outline } from './Outline'

type InformationBarProps = {
  story: Story
}
export function InformationBar({ story }: InformationBarProps): JSX.Element {
  function findPeople(): Person[] {
    return story.book.people.filter((person) => story.people.includes(person.personId))
  }

  const people = findPeople()
  const things = []
  const locations = []

  return (
    <div className="h-full lg:flex lg:flex-col hidden lg:w-1/3 p-3 bg-gray-100 overflow-y-auto">
      <h2 className="font-bold pb-1 text-2xl w-64">About This Story</h2>
      <div className="grid gap-2 grid-cols-1 sm:grid-cols-2 lg:grid-cols-1">
        <Status story={story} />
        <Occurred story={story} />
        {people.length > 0 && (
          <InformationSection title="Tags">
            {people.map((person, index) => (
              <span key={person.key}>
                {index > 0 && ', '}
                <PersonLink person={person} />
              </span>
            ))}
          </InformationSection>
        )}
        {things.length > 0 && (
          <InformationSection title="What">
            <div>-</div>
          </InformationSection>
        )}
        {locations.length > 0 && (
          <InformationSection title="Where">
            <div>-</div>
          </InformationSection>
        )}
        <InformationSection title="Outline" link={story.book.link + '/outline'}>
          <Outline currentStory={story} />
        </InformationSection>
      </div>
    </div>
  )
}

type SectionProps = WithChildren & {
  title: string
  className?: string
  link?: string
}
function InformationSection({ title, className, link, children }: SectionProps): JSX.Element {
  return (
    <div>
      <div className="flex">
        <h3 className="font-bold flex-1">{title}</h3>
        {link && (
          <Tooltip message="Edit">
            <Link to={link} className="hover:text-cyan-600">
              <EditIcon className="w-5" />
            </Link>
          </Tooltip>
        )}
      </div>
      <div className={classNames('pl-4', className)}>{children}</div>
    </div>
  )
}

type StatusProps = {
  story: Story
}
function Status({ story }: StatusProps): JSX.Element {
  const updater = useUpdateStory()
  const { showError } = useErrorNotification()

  async function handleSave(status: StoryStatusType): Promise<void> {
    try {
      await updater.update(story, { status })
    } catch (error) {
      showError('Error Updating title', error)
    }
  }

  return (
    <div className="flex items-center">
      <h3 className="font-bold pr-1">Status: </h3>
      <StatusMenu saving={updater.isLoading} status={story.status} onChange={handleSave} />
    </div>
  )
}

type OccurredProps = {
  story: Story
}
function Occurred({ story }: OccurredProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false)
  const updater = useUpdateStory()
  const { showError } = useErrorNotification()

  async function handleSave(occurred: StoryDate | undefined): Promise<void> {
    try {
      await updater.update(story, { occurred: occurred ?? null })
      setIsOpen(false)
    } catch (error) {
      showError('Error Updating title', error)
    }
  }

  return (
    <span onClick={() => setIsOpen(true)} className="rounded-md hover:ring-2 cursor-pointer">
      <span className="font-bold pr-1">Occurred: </span>
      {dateToString(story.occurred) || <span className="italic">No Specific Date</span>}
      <OccurredDialog
        key={String(isOpen)}
        isOpen={isOpen}
        occurred={story.occurred}
        onSave={handleSave}
        onClose={() => setIsOpen(false)}
      />
    </span>
  )
}
