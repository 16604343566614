import {
  ButtonSave,
  classNames,
  DatesForm,
  HelpText,
  NotReady,
  PageHeading,
  PagePadding,
  useErrorNotification,
  useRequiredParam,
} from '@life/components'
import { UnsavedStory, useAddStory, useBook } from '@life/frontend-model'
import { StoryDate } from '@life/model'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { canEditBook } from '../common'

export function AddStory() {
  type FormFields = { title: string; occurred: StoryDate }
  const bookSlug = useRequiredParam('bookSlug')
  const { book, isLoading, error } = useBook(bookSlug)
  const [occurredError, setOccurredError] = useState<string>()
  const navigate = useNavigate()
  const adder = useAddStory()
  const { showError } = useErrorNotification()
  const form = useForm<FormFields>({
    defaultValues: { title: '' },
  })

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = form

  if (isLoading || error || !book || !bookSlug) {
    return <NotReady type="Book" id={bookSlug} isLoading={isLoading} error={error} />
  }

  async function add(fields: FormFields): Promise<void> {
    if (!book) return // should never happen
    try {
      const story = new UnsavedStory(book, { ...fields, bookId: book.bookId, status: 'stub', content: [] })
      const saved = await adder.add(story)
      navigate('../stories/' + saved.storyId + '/edit')
    } catch (error) {
      showError('Error Adding Story', error)
    }
  }

  return (
    <PagePadding narrow>
      <PageHeading title="Add Story">Add Story</PageHeading>

      <form onSubmit={handleSubmit(add)} className="pt-8">
        <div className="mt-1 mb-4 relative rounded-md shadow-sm\">
          <input
            type="text"
            className={classNames(
              errors.title
                ? 'ring-red-400 border-red-400'
                : 'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300',
              'block w-full sm:text-sm rounded-md'
            )}
            placeholder="Title"
            {...register('title', {
              required: {
                value: true,
                message: 'Title is required',
              },
            })}
          />
        </div>
        <div className="">
          <label htmlFor="occurred" className="block text-sm font-medium text-gray-700">
            Story Date
          </label>
          <Controller
            render={({ field }) => (
              <DatesForm
                types={['single', 'range']}
                optional
                defaultValue={field.value}
                onChange={(value, error) => {
                  field.onChange(value)
                  setOccurredError(error)
                }}
              />
            )}
            control={control}
            name="occurred"
            defaultValue={undefined}
          />
          <HelpText error={occurredError ? { type: 'value', message: occurredError } : undefined}>
            When did this story occur? This date or date range will be used to suggest story order along with
            crosschecking against dates for people, places, and things in your story.
          </HelpText>
        </div>
        <div className="mt-8 text-center">
          <ButtonSave disabled={!isDirty || !!occurredError} clicked={adder.isLoading} canEdit={canEditBook(book)}>
            Add Story
          </ButtonSave>
        </div>
      </form>
    </PagePadding>
  )
}
