import { API, BookId, Logger } from '@life/model'
import { useMemo } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { serverRequest } from './api'
import { Book } from './book'
import { Collaborator } from './collaborator'
import { ONE_MINUTE } from './time'

const logger = new Logger('api-collaborator')

const listKey = (bookId: BookId) => `COLL:${bookId}`

export type CollaboratorState = {
  isLoading: boolean
  error?: API.GetErrors
  collaborators?: Collaborator[]
}
export function useCollaborators(book: Book): CollaboratorState {
  const query = useQuery<API.CollaboratorListSuccess, API.GetErrors>(
    listKey(book.bookId),
    () => listCollaborators({ bookId: book.bookId }),
    {
      retry: 1,
      staleTime: 15 * ONE_MINUTE,
    }
  )

  // Make sure hook result is stable
  const collaborators = useMemo(() => {
    logger.verbose('useCollaborators -> useMemo(collaborators)')
    // See story-api for explanation of this next line.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- use 'any' so we can delete the nonsense field
    delete (query.data as any)?.updatedAt
    return query.data?.list ? query.data?.list.map((coll) => new Collaborator(coll)) : undefined
  }, [query.data])
  const error = useMemo(() => query.error ?? undefined, [query.error])

  return {
    ...query,
    error,
    collaborators,
  }
}

export type UpdateCollaboratorState = {
  isUpdating: boolean
  update: (collaborator: Collaborator) => Promise<void>
}
export function useUpdateCollaborator(): UpdateCollaboratorState {
  const queryClient = useQueryClient()
  const mutation = useMutation((input: API.CollaboratorUpdateInput) => updateCollaborator(input))
  async function update(collaborator: Collaborator): Promise<void> {
    try {
      await mutation.mutateAsync({ collaborator: collaborator.toModel() })
      queryClient.setQueryData<API.CollaboratorListSuccess | undefined>(listKey(collaborator.bookId), (previous) => {
        if (!previous?.list) return previous
        previous.list = previous.list.map((coll) => (coll.userId === collaborator.userId ? collaborator : coll))
        // Use the "updatedAt" hack described in story-api to force Book to reload.
        return { ...previous, updatedAt: Date.now() }
      })
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, isUpdating: mutation.isLoading, update }
}

export type RemoveCollaboratorState = {
  isRemoving: boolean
  remove: (collaborator: Collaborator) => Promise<void>
}
export function useRemoveCollaborator(): RemoveCollaboratorState {
  const queryClient = useQueryClient()
  const mutation = useMutation((input: API.CollaboratorRemoveInput) => removeCollaborator(input))
  async function remove(collaborator: Collaborator): Promise<void> {
    try {
      await mutation.mutateAsync({ bookId: collaborator.bookId, userId: collaborator.userId })
      queryClient.setQueryData<API.CollaboratorListSuccess | undefined>(listKey(collaborator.bookId), (previous) => {
        if (!previous) return previous
        previous.list = previous.list.filter((coll) => coll.userId !== collaborator.userId)
        // Use the "updatedAt" hack described in story-api to force Book to reload.
        return { ...previous, updatedAt: Date.now() }
      })
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, isRemoving: mutation.isLoading, remove }
}

function listCollaborators(input: API.CollaboratorListInput): Promise<API.CollaboratorListSuccess> {
  return serverRequest<API.CollaboratorListInput, API.CollaboratorListSuccess>('/collaborator/list', input)
}

function updateCollaborator(input: API.CollaboratorUpdateInput): Promise<API.ResponseSuccess> {
  return serverRequest<API.CollaboratorUpdateInput, API.ResponseSuccess>('/collaborator/update', input)
}

function removeCollaborator(input: API.CollaboratorRemoveInput): Promise<API.ResponseSuccess> {
  return serverRequest<API.CollaboratorRemoveInput, API.ResponseSuccess>('/collaborator/remove', input)
}
