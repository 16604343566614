import { ButtonCancel, ButtonSave, LifeDialog } from '@life/components'
import { Book, Story } from '@life/frontend-model'
import { StoryContent } from '@life/model'
import { useState } from 'react'
import { FieldError } from 'react-hook-form'

type TitleDialogProps = {
  key: string // Trick to force dialog to reset state when it's opened. Should be set to String(isOpen)
  isOpen: boolean
  book: Book
  content?: StoryContent[]
  onSave: (story: Story) => void
  onClose: () => void
}
export function AddStoryDialog({ isOpen, content, onSave, onClose }: TitleDialogProps): JSX.Element {
  const [error, setError] = useState<FieldError>()

  function handleSave() {
    console.warn('TODO - save the story')
    onClose()
  }

  const shortcuts = { Escape: onClose, Enter: () => !error && handleSave() }
  return (
    <LifeDialog modal isOpen={isOpen} title="Add Story" onClose={onClose} shortcuts={shortcuts}>
      <LifeDialog.Content className="w-full sm:w-96">form to add story goes here</LifeDialog.Content>
      <LifeDialog.Actions>
        <ButtonCancel onClick={onClose} />
        <ButtonSave onClick={handleSave} disabled={!!error}>
          Save
        </ButtonSave>
      </LifeDialog.Actions>
    </LifeDialog>
  )
}
