import { Book } from '@life/frontend-model'
import { isStoryText, StoryContent, StoryId, StoryImage, StoryNoun } from '@life/model'
import { createContext, ReactNode, useContext, useRef } from 'react'

/**
 * Book Index builder.
 * The Book index must be generated dynamically from the Book content.
 * For each indexed element, the Index provides a title and a list of locations
 * where it is referenced -> a link ID (so it can be scrolled to) and context,
 * which is text in or surrounding the element to help the user identify it.
 * Elements indexed are person|place|thing|image.
 *
 * The implementation is complicated by the fact that elements can appear multiple
 * times in the content without any uniquely identifying information.
 * The approach taken here is to generate the index before the book is rendered,
 * and add uniquely identifying info to the element, as `linkId`.
 *
 * Algorithm:
 * The index is stored in the Builder as a collection of lookups:
 *   { [element type] -> { [element id] -> Cache Entry }
 *   where Cache Entry holds the index entry title and an set of location data
 *   { [story ID] -> context[] }
 * To Build the Index:
 *   - addEntry(story id, type, id, surrounding context):
 *     -- in the Cache Entry for the type and id, store the title (if necessary)
 *        and add the location data
 *   - scan book contents
 *     -- add an entry for each link
 *     -- set the `linkId` in the element to the added entry ID
 * During story render:
 *   - add an `id` attribute to the element's HTML tag with value `element.linkId`
 * To display the Index:
 *   - findEntries(type)
 *     -- find all Cache Entries for the type, as { [element ID] -> Cache Entry }
 *     -- map the entries to return title and list of locations as above
 *
 * Note: The index is built dynamically, but to keep from doing work in the constructor,
 * it is built the first time one of the IndexBuilder public methods is called.
 */

/** The external Index Entry */
export type IndexEntry = {
  /** ID of the element this entry refers to */
  elementId: string
  /** Title to display in the Index */
  title: string
  locations: {
    /** Story the element is found in the document */
    storyId: StoryId
    /** Link ID of the element in the document */
    linkId: string
    /** Surrounding text to provide context */
    context: string
  }[]
}

type EntryType = StoryNoun | StoryImage
type EntryTypeName = EntryType['type']
type EntryTypeId = EntryType['id']
type EntryContext = { siblingLeft?: StoryContent; siblingRight?: StoryContent }

type EntryCache = Partial<Record<EntryTypeName, Record<EntryTypeId, CacheEntry>>>
type CacheEntry = {
  elementId: string
  title: string
  locations: {
    [storyId: StoryId]: IndexEntry['locations']
  }
}

export class IndexBuilder {
  /** The cache of entries. Two maps for efficient storage & retrieval: by type name, by id. */
  private _entries: EntryCache | undefined

  constructor(private book: Book) {}

  private get entries(): EntryCache {
    if (!this._entries) {
      this._entries = {}
      this.buildIndex()
    }
    return this._entries
  }

  /** Adds an entry to the index and returns its unique ID. */
  addEntry(storyId: StoryId, entry: EntryType, siblings?: EntryContext): string {
    let entriesByType = this.entries[entry.type]
    if (!entriesByType) {
      entriesByType = {}
      this.entries[entry.type] = entriesByType
    }
    let indexEntry = entriesByType[entry.id]
    if (!indexEntry) {
      indexEntry = { elementId: entry.id, title: this.buildEntryTitle(entry), locations: {} }
      entriesByType[entry.id] = indexEntry
    }
    let entryLocation = indexEntry.locations[storyId]
    if (!entryLocation) {
      entryLocation = []
      indexEntry.locations[storyId] = entryLocation
    }
    const linkId = this.buildlinkId(storyId, entry.id, entryLocation.length)
    const context = this.buildEntryContext(entry, siblings)
    entryLocation.push({ storyId, linkId, context })
    return linkId
  }

  findEntries(typeName: EntryTypeName): IndexEntry[] {
    const entriesByType = this.entries[typeName] ?? {}
    const cachedEntries = Object.values(entriesByType)
    const entries: IndexEntry[] = cachedEntries.map((cacheEntry) => ({
      elementId: cacheEntry.elementId,
      title: cacheEntry.title,
      locations: Object.values(cacheEntry.locations).flat(),
    }))
    if (typeName === 'person') {
      Object.entries(entriesByType).forEach(([elementId, cacheEntry]) => {
        const person = this.book.findPerson(elementId)
        person?.otherLastNames?.forEach((lastName) => {
          const title = `${lastName}, ${person.givenNames} (${person.lastName})`.trim()
          entries.push({
            elementId,
            title,
            locations: Object.values(cacheEntry.locations).flat(),
          })
        })
      })
    }
    return entries.sort((a, b) => a.title.localeCompare(b.title))
  }

  private buildlinkId(storyId: StoryId, id: string, index: number): string {
    return `${storyId}-${id}-${index}`
  }

  private buildEntryTitle(entry: EntryType): string {
    switch (entry.type) {
      case 'person':
        return this.book.findPerson(entry.id)?.formalNameLastFirst ?? `Unknown Person ${entry.id}`
      case 'location':
        return this.book.findLocation(entry.id)?.description ?? `Unknown Location ${entry.id}`
      case 'thing':
        return `Unknown Thing ${entry.id}`
      // return this.book.findThing(entry.id)?.description ?? `Unknown Thing ${entry.id}`
      case 'image':
        return entry.caption ?? this.book.findImage(entry.id)?.notes ?? `Unlabeled Image ${entry.id}`
    }
  }

  private buildEntryContext(entry: EntryType, _siblings: EntryContext | undefined): string {
    switch (entry.type) {
      case 'person':
      case 'location':
      case 'thing':
        return entry.children[0]?.text
      case 'image':
        return this.buildEntryTitle(entry)
    }
  }

  private buildIndex(): void {
    this.book.allChapters.forEach((ch) => this.buildIndexContents(ch.storyId, ch.content))
  }

  private buildIndexContents(storyId: StoryId, content: StoryContent[]): void {
    content.forEach((c) => this.buildIndexContent(storyId, c))
  }

  private buildIndexContent(storyId: StoryId, content: StoryContent): void {
    if (isStoryText(content)) return
    switch (content.type) {
      case 'paragraph':
        this.buildIndexContents(storyId, content.children)
        break
      case 'substory':
        this.buildIndexContents(content.id, content.children)
        break
      case 'person':
      case 'location':
      case 'thing':
      case 'image':
        content.linkId = this.addEntry(storyId, content)
        break
    }
  }
}

/**
 * Components that allow managing IndexBuilder at a "global" state.
 */
export type IndexBuilderState = IndexBuilder
const IndexBuilderContext = createContext<IndexBuilderState | null>(null)

type ProviderProps = {
  book: Book
  children: ReactNode
}
export function IndexBuilderProvider({ book, children }: ProviderProps): JSX.Element {
  const indexBuilderRef = useRef<IndexBuilder>(new IndexBuilder(book))
  const value = indexBuilderRef.current
  return <IndexBuilderContext.Provider value={value}>{children}</IndexBuilderContext.Provider>
}

export function useIndexBuilder(): IndexBuilderState {
  const state = useContext(IndexBuilderContext)
  if (!state) throw new Error('useIndexBuilder must be used within an IndexBuilderProvider')
  return state
}
